import React from "react";
import { useNavigate } from "react-router-dom";
import { ccfRoutes } from "../../routes/routes";

// Define data for the roles
const roleData = [
  {
    id: 1,
    title: "Center",
    description:
      "Explore innovative services that increase operational efficiency.",
    image:
      "https://a360csastorage.blob.core.windows.net/childcare/a6b41822-54ed-40c0-9714-fb988cc2c203-fileName-can-achive-center.png",
    route: ccfRoutes.center,
  },
  {
    id: 2,
    title: "Teacher",
    description: "Discover convenient services and of course – Adventures!",
    image:
      "https://a360csastorage.blob.core.windows.net/childcare/c4994af2-0006-4f7f-a985-35ed6e28737b-fileName-can-achive-teacher.png",
    route: ccfRoutes.teacher,
  },
  {
    id: 3,
    title: "Parent",
    description: "Learn about the childcare industry and find the best center.",
    image:
      "https://a360csastorage.blob.core.windows.net/childcare/a010c7ef-beb2-449d-9399-a379bf509312-fileName-can-achive-parent.png",
    route: ccfRoutes.parent,
  },
];

const CanAchive = () => {
  const navigate = useNavigate();

  const handleCardClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      {roleData.map((role) => (
        <div
          key={role.id}
          className="h-full flex flex-col relative border border-secondaryVariant2 hover:border-primary rounded-[20px] pt-4 lg:pt-6 cursor-pointer hover:shadow-blogCardShadow"
          onClick={() => handleCardClick(role.route)}
        >
          <div className="px-6 md:px-3 xl:px-6 flex-1">
            <h3 className="text-2xl font-semibold text-secondary mb-3 inline-flex items-center gap-2">
              <span className="text-secondary font-normal text-2xl">I’m a</span>
              {role.title}
            </h3>
            <p className="text-secondary text-lg line-clamp-3">
              {role.description}
            </p>
          </div>
          <img
            className="w-full  bottom-0 -z-10"
            src={role.image}
            alt={role.title}
          />
        </div>
      ))}
    </>
  );
};

export default CanAchive;
