
import TrustedCustomerCard from "./TrustedCustomerCard";

const testimonials = [
  {
    id: 1,
    quote:
      "The whole process was easy and efficient. I received my completed paperwork in an appropriate time frame without any hiccups. The Doctor was very professional and thorough. I will be recommending ChildCare Forward to all of my fellow teachers.",
    name: "Brittnee",
    rating: 5.0,
  },
  {
    id: 2,
    quote:
      "My experience with this company was great to say the least. The sign-up process was easy, and the appointment times were convenient for my staff. The Dr. was friendly, professional, and personable with my teachers. Once the medical was completed, he advised that it would be sent by the end of the day, and we received it within a few hours. Needless to say, I will be using this company again for my staff when we need medicals done. Thank you again!",
    name: "Karlia",
    rating: 5.0,
  },
  {
    id: 3,
    quote:
      "Fast appointment, excellent service, the appointment was easy. I would recommend!",
    name: "Salma",
    rating: 5.0,
  },

  {
    id: 4,
    quote:
      "Childcare Forward has been great, the website is easy to manage and make appointments. If I have a question and need to call someone always return the call within a reasonable time frame. The whole process is very professional and I would recommend this service to anyone.",
    name: "Valencia",
    rating: 5.0,
  },
];

const TrustedCustomer = () => {
  return (
    <div className="py-8 lg:py-12 bg-secondaryNeutral">
      <div className="">
        <div className="container">
        <h2 className="text-2xl md:text-xl xl:text-[28px] text-secondary font-semibold mb-6">
          Trusted by Happy Customers
        </h2>
        </div>
        <div className="relative w-full overflow-hidden" aria-hidden={true}>
        <div className="flex w-max animate-marquee gap-4">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="flex-shrink-0 max-w-[400px] bg-white rounded-[10px]  py-4 xl:px-6 px-4"
            >
              <TrustedCustomerCard testimonial={testimonial} />
            </div>
          ))}
          {/* Duplicate content for seamless loop */}
          {testimonials.map((testimonial) => (
            <div
              key={`duplicate-${testimonial.id}`}
              className="flex-shrink-0 max-w-[400px] bg-white rounded-[10px] py-4 xl:px-6 px-4"
            >
              <TrustedCustomerCard testimonial={testimonial} />
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedCustomer;
