import Dropdown from "react-bootstrap/Dropdown";
import { BiEdit } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin3Line } from "react-icons/ri";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IChecklist } from "../../types/IChecklist.interface";
import { useMemo } from "react";
import { useCompleteChecklist } from "../../hooks/useCompleteDailyChecklist";

const columnHelper = createColumnHelper<IChecklist>();

interface IProps {
  handleEdit: (id: string) => void;
  rowToEdit: string;
  initials: string;
  handleChange: (value: string) => void;
  dailyChecklist: IChecklist[] | undefined;
}

const DailyChecklistTable: React.FC<IProps> = ({
  handleEdit,
  rowToEdit,
  initials,
  handleChange,
  dailyChecklist,
}) => {
  const { mutate } = useCompleteChecklist();

  const columns = useMemo(
    () => [
      columnHelper.accessor("time", {
        id: "time",
        header: () => (
          <div className="text-center">
            <span>Time</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-center">
            <span className="w-full">{info.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor("task", {
        id: "task",
        header: () => (
          <div className="text-center">
            <span>Task</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-start">
            <span className="w-full">{info.getValue()}</span>
          </div>
        ),
      }),

      columnHelper.accessor("completedBy", {
        id: "initials",
        header: () => (
          <div className="text-center">
            <span>Initials</span>
          </div>
        ),
        cell: (info) => (
          <input
            key={info.row.original._id}
            onFocus={(e) => {
              e.preventDefault();
              handleEdit(info.row.original._id);
            }}
            maxLength={2}
            // disabled={info.row.original._id !== rowToEdit}
            className="text-center "
            value={
              info.row.original._id !== rowToEdit ? info.getValue() : initials
            }
            onChange={(e) => {
              e.preventDefault();
              handleChange(e.target.value);
            }}
            onBlur={(e) => {
              if (
                e.target.value.length === 2 &&
                info.getValue() !== e.target.value
              ) {
                mutate({
                  checklistId: info.row.original._id,
                  initials: e.target.value,
                });
              }
            }}
          />
        ),
      }),

      columnHelper.display({
        id: "action",
        header: () => (
          <div className="text-center">
            <span>Action</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-center flex items-center justify-center gap-2">
            <button>
              <BiEdit className="w-6 h-6 text-secondaryVariant" />
            </button>
            <button>
              <RiDeleteBin3Line className="w-6 h-6 text-dangerAlert" />
            </button>
            {/* <Dropdown className="checklistDrop">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="flex items-center flex-col top-[100%] right-[0_!important] left-auto  z-10  p-2 w-full px-1.5 bg-white shadow-customShadow rounded-lg absolute min-w-[137px]">
                <div className="flex flex-col items-start">
                  <Dropdown.Item
                    onClick={() => handleEdit(info.row.original._id)}
                    className="flex items-center gap-2.5 text-secondary px-3 py-1.5"
                  >
                    <BiEdit className="w-4 h-4" />
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item className="flex items-center gap-2.5 text-secondary px-3 py-1.5">
                    <RiDeleteBin3Line className="w-4 h-4" />
                    Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        ),
      }),
    ],
    []
  );
  // const columns = [
  //   columnHelper.accessor("time", {
  //     id: "time",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Time</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-center">
  //         <span className="w-full">{info.getValue()}</span>
  //       </div>
  //     ),
  //   }),
  //   columnHelper.accessor("task", {
  //     id: "task",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Task</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-start">
  //         <span className="w-full">{info.getValue()}</span>
  //       </div>
  //     ),
  //   }),

  //   columnHelper.accessor("completedBy", {
  //     id: "initials",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Initials</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <input
  //         key={info.row.original._id}
  //         onFocus={(e) => {
  //           e.preventDefault();
  //           handleEdit(info.row.original._id);
  //         }}
  //         maxLength={2}
  //         // disabled={info.row.original._id !== rowToEdit}
  //         className="text-center "
  //         value={
  //           info.row.original._id !== rowToEdit ? info.getValue() : initials
  //         }
  //         onChange={(e) => {
  //           e.preventDefault();
  //           handleChange(e.target.value);
  //         }}
  //         onBlur={(e) => {
  //           // Only blur if we have 2 characters
  //           if (e.target.value.length < 2) {
  //             e.target.focus();
  //           }
  //         }}
  //       />
  //     ),
  //   }),

  //   columnHelper.display({
  //     id: "action",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Action</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-center relative">
  //         {/* <button>
  //           {info.getValue()} <AiOutlineEye className="w-6 h-6 text-secondary" />
  //         </button> */}
  //         <Dropdown className="checklistDrop">
  //           <Dropdown.Toggle variant="success" id="dropdown-basic">
  //             <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu className="flex items-center flex-col top-[100%] right-[0_!important] left-auto  z-10  p-2 w-full px-1.5 bg-white shadow-customShadow rounded-lg absolute min-w-[137px]">
  //             <div className="flex flex-col items-start">
  //               <Dropdown.Item
  //                 onClick={() => handleEdit(info.row.original._id)}
  //                 className="flex items-center gap-2.5 text-secondary px-3 py-1.5"
  //               >
  //                 <BiEdit className="w-4 h-4" />
  //                 Edit
  //               </Dropdown.Item>
  //               <Dropdown.Item className="flex items-center gap-2.5 text-secondary px-3 py-1.5">
  //                 <RiDeleteBin3Line className="w-4 h-4" />
  //                 Delete
  //               </Dropdown.Item>
  //             </div>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </div>
  //     ),
  //   }),
  // ];

  const table = useReactTable({
    data: dailyChecklist || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="scrollHide   overflow-y-auto h-[calc(100vh-480px)]">
      <table className="w-full bg-white rounded-t-lg checklistTable">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="p-5 text-left text-base font-medium text-secondary capitalize"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {/* Adding a row for Morning, Evening, etc. */}
          <tr className="bg-[#FFAE00] text-white text-base text-center h-[31px]">
            <td
              colSpan={table.getHeaderGroups()[0]?.headers.length}
              className="px-3"
            >
              Morning
            </td>
          </tr>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="even:bg-secondaryNeutral">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DailyChecklistTable;
