import React from "react";
import { IoCloseOutline } from "react-icons/io5";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
  message: string;
  actionText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onProceed,
  message,
  actionText,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white rounded-lg shadow-lg w-[464px]">
        <div className="flex items-center justify-between py-4 px-6">
          <h2 className="text-2xl font-semibold text-secondary">Confirmation</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div>

        <div className="py-6 px-6 space-y-4">
          {/* Message */}
          <p className="text-base text-secondaryVariant">{message}</p>
          
          {/* Action Text */}
          <p className="text-base font-medium text-secondary">{actionText}</p>
        </div>

        <div className="flex items-center justify-end gap-4 py-4 px-6 border-t">
          <button
            className="text-sm font-medium text-secondaryVariant2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="btnPrimary max-w-[95px] bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
            onClick={() => {
              onProceed();
              onClose();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
