import React from 'react';
import { BiCheck } from 'react-icons/bi';

interface Column {
  id: string;
  label: string;
  isChecked: boolean;
}

interface SelectColumnModalProps {
  isOpen: boolean;
  onClose: () => void;
  columnsList: Column[];
  onToggleColumn: (id: string) => void;
}

const SelectColumnModal: React.FC<SelectColumnModalProps> = ({
  isOpen,
  onClose,
  columnsList,
  onToggleColumn,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h3 className="text-lg font-bold mb-4">Select Columns</h3>
        <ul className=" grid grid-cols-2 gap-4">
          {columnsList.map((column) => (
            <li key={column.id} className="flex items-center gap-2">
              <div className="relative inline-block w-5 h-5">
                <input
                  type="checkbox"
                  checked={column.isChecked}
                  className="hidden peer"
                  id={column.id}
                  name="column"
                  onChange={() => onToggleColumn(column.id)}
                />
                <label
                  htmlFor={column.id}
                  className="peer-checked:bg-primary border border-secondaryVariant2 peer-checked:border-primary rounded-[4px] cursor-pointer w-full h-full transition-colors duration-300 ease-in-out flex items-center justify-center"
                >
                  <BiCheck className="text-white" />
                </label>
              </div>
              <label htmlFor={column.id} className="text-secondary cursor-pointer flex-1">
                {column.label}
              </label>
            </li>
          ))}
        </ul>
          <div className="flex items-center justify-end gap-4 mt-4">
          <button className="text-sm font-medium text-secondaryVariant2"  onClick={onClose}>
            Cancel
          </button>
          <button
            className="btnPrimary max-w-[81px] flex justify-center"
            onClick={onClose}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectColumnModal;
