import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import InputField from "../inputsFields/InputField";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ReactSelect from "../reactSelect/ReactSelect";

const FillTaskInformation = () => {
  const [openKeys, setOpenKeys] = useState<string[]>(["0"]); // Manage multiple open accordions

  const handleAccordionToggle = (key: string) => {
    setOpenKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Close the accordion
          : [...prevKeys, key] // Open the accordion
    );
  };

  return (
    <div className="bg-white p-6 rounded-xl mb-6">
      <Accordion activeKey={openKeys}>
        <Accordion.Item eventKey="0" className="mb-7">
          <Accordion.Header
            onClick={() => handleAccordionToggle("0")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Essential{" "}
              {openKeys.includes("0") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-3 gap-7">
              <div>
                <InputField
                  type="text"
                  placeholder=""
                  label="Days Before Reference Date"
                  required
                />
              </div>
              <div>
                <InputField
                  type="text"
                  placeholder=""
                  label="Days Before Reference Date"
                  required
                />
              </div>
              <div>
                <ReactSelect label="Reference Date" placeholder="" />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header
            onClick={() => handleAccordionToggle("1")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Advanced{" "}
              {openKeys.includes("1") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-4 gap-3">
              <div>
                <ReactSelect label="Status" placeholder="" required />
              </div>
              <div>
                <label htmlFor="" className="text-base text-secondaryVariant">
                  As of Date
                </label>
                <input
                  type="date"
                  required
                  className="w-full border-secondaryVariant2 border rounded-lg py-3.5 px-2 mt-1"
                />
              </div>
              <div>
                <ReactSelect label="Classroom" placeholder="" required />
              </div>
              <div>
                <ReactSelect label="Weekday" placeholder="" required />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="text-[#F6EAEA] my-7" />
      <div className="flex items-center gap-3">
        <button className="btnPrimary max-w-[182px]">Save</button>
        <button className="btnSimple max-w-[182px]">cancel</button>
      </div>
    </div>
  );
};

export default FillTaskInformation;
