import React, { useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoPlus } from 'react-icons/go';
import SelectColumnModal from '../modals/SelectColumnModal';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { HiOutlineTrash } from 'react-icons/hi';
import { TfiReload } from 'react-icons/tfi';

type Person = {
  initials: string;
  comments: string;
  firstName: string;
  lastName: string;
  classRoomName: string;
  dateStartCenter: string;
};

const columnHelper = createColumnHelper<Person>();

const columns = [
  columnHelper.accessor('initials', {
    header: 'Initials',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('comments', {
    header: 'Comments',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('lastName', {
    header: 'Last Name',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('classRoomName', {
    header: 'Classroom Name',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('dateStartCenter', {
    header: 'Date Start Center',
    cell: (info) => <div>{info.getValue()}</div>,
  }),
];

const defaultData: Person[] = [
  {
    initials: 'AM',
    comments: 'Lorem ipsum dolor sit amet.',
    firstName: 'Alex',
    lastName: 'Harbour',
    classRoomName: 'Class-A',
    dateStartCenter: '15/08/2024',
  },
  {
    initials: 'AM',
    comments: 'Lorem ipsum dolor sit amet.',
    firstName: 'Alex',
    lastName: 'Harbour',
    classRoomName: 'Class-A',
    dateStartCenter: '15/08/2024',
  },
];

const TaskGenerationListing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data] = useState(() => [...defaultData]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [columnsCheck, setColumnsCheckbox] = useState([
    { id: 'col1', label: 'Initials', isChecked: true },
    { id: 'col2', label: 'Comments', isChecked: true },
    { id: 'col3', label: 'First Name', isChecked: true },
    { id: 'col4', label: 'Last Name', isChecked: true },
    { id: 'col5', label: 'Classroom Name', isChecked: true },
    { id: 'col6', label: 'Date Start Center', isChecked: true },
  ]);

  const handleToggleColumn = (id: string) => {
    const updatedColumns = columnsCheck.map((column) =>
      column.id === id ? { ...column, isChecked: !column.isChecked } : column
    );
    setColumnsCheckbox(updatedColumns);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className="scrollHide overflow-y-auto h-[calc(100vh-350px)]">
        <table className="w-full bg-white rounded-t-lg">
        <thead className="sticky top-0 bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-5 text-left text-base font-medium text-secondary capitalize"
                  >
                    <div className="flex items-center gap-3 justify-between">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.columnDef.header === 'Initials' ? (
                        <></>
                      ) : header.column.columnDef.header === 'Comments' ? (
                        <button
                          onClick={() => console.log('Comments button clicked')}
                          className="bg-green-500 text-white w-7 h-7 flex items-center justify-center rounded-md"
                        >
                          <TfiReload />
                        </button>
                      ) : (
                        <Dropdown className="relative inline-flex">
                          <Dropdown.Toggle
                            variant="success"
                            className="!p-0 !border-0 bg-transparent"
                          >
                            <BsThreeDotsVertical className="w-4 h-4 text-secondary" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-white transform-none p-2 rounded-lg shadow-lg min-w-[203px]">
                            <Dropdown.Item
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                              onClick={handleOpenModal}
                            >
                              <GoPlus className="text-md" />
                              Add Column
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                            >
                              <IoIosArrowRoundBack className="text-md" />
                              Shift Column Left
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                            >
                              <IoIosArrowRoundForward className="text-md" />
                              Shift Column Right
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                            >
                              <HiOutlineTrash className="text-md" />
                              Remove Column
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-secondaryNeutral">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="odd:bg-natural even:bg-white">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`p-5 text-base text-secondaryVariant border-b border-secondaryNeutral ${
                      cell.column.id === 'comments' ? 'w-[237px]' : ''
                    }`}
                  >
                    {cell.column.id === 'comments' ? (
                      <span className="p-4 bg-white rounded-lg block border border-secondaryVariant2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </span>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SelectColumnModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          columnsList={columnsCheck}
          onToggleColumn={handleToggleColumn}
        />
      )}
    </div>
  );
};

export default TaskGenerationListing;
