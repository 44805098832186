import { IClassroomSetup } from "../../types/IClassroomSetup.interface";

interface Options {
  value: string;
  label: string;
}
interface IProps {
  classrooms: IClassroomSetup[];
  classroomOptions: Options[];
}

const EssentialTransition: React.FC<IProps> = ({
  classrooms,
  classroomOptions,
}) => {
  return (
    <div>
      <h3 className="text-secondary font-semibold text-xl mb-3">Section 3</h3>
      <div className="bg-white p-6 rounded-xl space-y-3">
        <h2 className="text-2xl font-medium mb-6 flex items-start gap-2">
          Transitions
        </h2>
        <div className="grid grid-cols-12 gap-4 bg-[#F2F2F2] p-2 rounded-md">
          <div className="col-span-2">
            <p className="text-base text-secondary font-semibold">
              Classroom <br />{" "}
              <span className="text-base font-semibold "> Name *</span>
            </p>
          </div>
          <div className="col-span-8">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Minimum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Maximum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-base text-secondary font-semibold ">
              Student <br /> Transitions To
            </p>
          </div>
        </div>

        {classrooms?.map((item, index) => {
          const transitionClassroom = classroomOptions.find(
    (option) => option.value === item.transition?.TransitionClassroom
  );

          return (
            <div
              key={index}
              className="grid grid-cols-12 gap-4 px-2 rounded-md"
            >
              <div className="col-span-2">{item.classroomName}</div>
              <div className="col-span-8">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="grid grid-cols-4 gap-x-3 bg-[#F2F2F2] text-sm text-secondary font-semibold m-0 p-1">
                      <div>{item.ageRange.minimumAge.days}</div>
                      <div>{item.ageRange.minimumAge.months}</div>

                      <div>{item.ageRange.minimumAge.years}</div>
                      <div className={"text-primary"}>
                        {item.ageRange.minimumAge.ageInDays}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-4 gap-x-3 bg-[#F2F2F2] text-sm text-secondary font-semibold m-0 p-1">
                      <div>{item.ageRange.maximumAge.days}</div>
                      <div>{item.ageRange.maximumAge.months}</div>

                      <div>{item.ageRange.maximumAge.years}</div>
                      <div className={item.ageRange ? "text-primary" : ""}>
                        {item.ageRange.maximumAge.ageInDays}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex gap-4">
                  <div className="flex-1">
                    {transitionClassroom?.label ?? "None"}
                  </div>
                  <div className="flex-[0_0_71px]"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EssentialTransition;
