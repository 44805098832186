import React, { useState } from "react";
import CustomCheckbox from "../../components/customCheckbox/CustomCheckbox";
import { Parent } from "../../types/IStudentSetup.interface";
import { studentSvc } from "../../services/student.service";
import Input from "react-phone-number-input/input";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import { queryClient } from "../../../../config/useQuery.config";

interface IProps {
  parentInfo: Parent | undefined;
  onCancel: (save?: boolean) => void;
  index?: number;
}

const ParentEdit: React.FC<IProps> = ({ parentInfo, onCancel, index }) => {
  const [parentInformation, setParentInformation] = useState(parentInfo);
  const [errors, setErrors] = useState<Partial<Record<keyof Parent, string>>>(
    {}
  );
  const validateForm = () => {
    const newErrors: Partial<Record<keyof Parent, string>> = {};
    const requiredFields: (keyof Parent)[] = [
      "firstName",
      "lastName",
      "phone",
      "email",
      "street",
      "city",
      "state",
      "postalCode",
      "emergencyContact",
      // "centerId",
      // "centerSetupId",
    ];

    requiredFields.forEach((field) => {
      if (!parentInformation || !parentInformation[field]) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() +
          field
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim()
        } is required`;
      }
    });

    // Email validation
    if (
      parentInformation?.email &&
      !/\S+@\S+\.\S+/.test(parentInformation.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    // Phone number validation (simple check for numeric value)
    // if (parentInformation?.phone && !/^\d+$/.test(parentInformation.phone)) {
    //   newErrors.phone = "Phone number should contain only digits";
    // }

    // Emergency phone validation
    // if (
    //   parentInformation?.emergencyPhone &&
    //   !/^\d+$/.test(parentInformation.emergencyPhone)
    // ) {
    //   newErrors.emergencyPhone =
    //     "Emergency phone number should contain only digits";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      if (parentInformation?._id) {
        try {
          const resp = await studentSvc.updateParentInfo(
            parentInformation._id,
            parentInformation
          );

          if (resp?.ok) {
            console.log("response from update", resp);
            queryClient.invalidateQueries({ queryKey: ["student"] });

            onCancel(true);
          }
        } catch (error) {
          console.error("Error updating parent information:", error);
        }
      } else {
        console.log("Parent not found");
      }
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <>
      <div className="border border-black rounded-xl p-6 mb-6 lg:mb-11">
        <h2 className="text-xl text-secondary font-medium mb-6">
          Parent Information
        </h2>
        <div>
          <h3 className="text-lg text-secondaryVariant font-medium mb-6">
            Parent {(index as number) + 1 || 1}
          </h3>
          <div className="grid grid-cols-6 gap-3 mb-6">
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                First Name
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.firstName}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                Last Name
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.lastName}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                Email
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.email}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                Phone Number
                <RequiredAsterisk />
              </label>
              <Input
                // type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                country="US"
                placeholder="(123) 456-7890"
                value={parentInformation?.phone}
                onChange={(value) => {
                  setParentInformation((prev: any) => ({
                    ...prev,
                    phone: value,
                  }));
                }}
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                State
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.state}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    state: e.target.value,
                  }))
                }
              />
            </div>
            <div></div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                City
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.city}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                Street
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.street}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    street: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="text-secondaryVariant text-base mb-1 block">
                Zip Code
                <RequiredAsterisk />
              </label>
              <input
                type="text"
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                value={parentInformation?.postalCode}
                onChange={(e) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    postalCode: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-5 gap-8 mb-6">
            <div className="flex items-center gap-4">
              <CustomCheckbox
                checked={parentInformation?.emergencyContact}
                onChange={(checked) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    emergencyContact: checked,
                  }))
                }
              />
              <span className="text-base text-secondary">
                Emergency Contact
              </span>
            </div>
            <div className="flex items-center gap-4">
              <CustomCheckbox
                checked={parentInformation?.livesWith}
                onChange={(checked) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    livesWith: checked,
                  }))
                }
              />
              <span className="text-base text-secondary">Lives with</span>
            </div>
            <div className="flex items-center gap-4">
              <CustomCheckbox
                checked={parentInformation?.canPickUp}
                onChange={(checked) =>
                  setParentInformation((prev: any) => ({
                    ...prev,
                    canPickUp: checked,
                  }))
                }
              />
              <span className="text-base text-secondary">Can Pick up</span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button onClick={onSave} className="btnPrimary max-w-[181px]">
            Save
          </button>
          <button
            onClick={() => onCancel()}
            className="btnSimple max-w-[181px]"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ParentEdit;
