import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";
import { IMonthlyReqBody } from "../types/IChecklist.interface";

export const useAddMonthlyChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (reqBody: IMonthlyReqBody) =>
      checklistSvc.createMonthlyChecklist(reqBody),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ChecklistQueryKeyString.MonthlyChecklist],
      });
    },
  });
};
