import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { queryClient } from "../../../../../config/useQuery.config";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import { Classroom } from "../../../types/IEnrollmentTrackingInfo";
import SessionTime from "../../components/Session/SessionTime";
import AutoMovements from "../../components/autoMovements/AutoMovements";
import EnrolTrackingOptimization from "../../components/enrolTrackingOptimization/EnrolTrackingOptimization";
import CalendarData from "../../components/enrollmentCalendar/CalendarData";
import EnrollmentCalendar from "../../components/enrollmentCalendar/EnrollmentCalendar";
import WarningModal from "../../components/modals/WarningModal";
import StudentEnrollmentTable from "../../components/tables/StudentEnrollmentTable";
import { QueryKeyString } from "../../enums/queryKey.enum";
import { useYearlyEnrollments } from "../../hooks/useYearlyEnrollments";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";
import Breadcrumb from "../../../../hr-setup_modules/components/Breadcrumb";
import { forayRoutes } from "../../../routes/routes";
import SkippedPastTransitions from "../../components/skippedPastTransitions/SkippedPastTransitions";
import { StudentExecutions } from "../../enums/studentExecutions.enum";
import { SessionType } from "../../../enums/Foray.enums";
import { classroomSvc } from "../../../services/classroom.service";

const generateNext365Days = () => {
  const days = [];
  const currentDate = new Date();

  for (let i = 0; i < 365; i++) {
    const fullDate = new Date(currentDate);
    fullDate.setDate(currentDate.getDate() + i);

    const dayName = fullDate.toLocaleDateString("en-US", { weekday: "short" });
    const dayDate = fullDate.toLocaleDateString("en-US", { day: "2-digit" });
    const monthName = fullDate.toLocaleDateString("en-US", { month: "long" });
    const year = fullDate.getFullYear();

    days.push({
      day: dayName,
      date: dayDate,
      month: monthName,
      year,
      fullDate,
    });
  }

  return days;
};

const EnrollmentTracking = ({ month, year }: any) => {
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [previewID, setPreviewID] = useState("");
  const [showLeaveWarningModal, setShowLeaveWarningModal] = useState(false);
  const [switches, setSwitches] = useState<IEnrollmentSwitchesState>({
    strategicView: true,
    enrollmentNo: false,
    ageViolation: false,
    movements: false,
    optimization: false,
  });
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "Enrollment Tracking & Optimization", isCurrent: true },
  ];
  const [visibleMonthYear, setVisibleMonthYear] = useState<string>("");
  const [visibleTables, setVisibleTables] = useState<{
    [key: string]: boolean;
  }>({});
  const daysData = generateNext365Days();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.previewId) return;
    setPreviewID(state?.previewId);
    setSwitches((prev) => ({ ...prev, optimization: true }));
    refetchEnrollmentInfo();
  }, [state?.previewId]);

  const handleToggle = async (switchName: string) => {
    if (switchName === "optimization" && switches.optimization) {
      setShowLeaveWarningModal(true);
    } else if (
      //first toggle
      switchName === "optimization" &&
      switches.optimization === false
    ) {
      const resp = await EnrollmentTrackingSvc.createPreview();
      setPreviewID(resp?.preview?.previewId);
      setSwitches((prevState: any) => ({
        ...prevState,
        [switchName]: !prevState[switchName],
      }));
    } else {
      setSwitches((prevState: any) => ({
        ...prevState,
        [switchName]: !prevState[switchName],
      }));
    }
  };

  const closeModal = async () => {
    setSwitches((prevState: any) => ({
      ...prevState,
      optimization: false,
    }));
    window.history.replaceState({}, "");
    setPreviewID("");
    await queryClient.invalidateQueries({
      queryKey: [QueryKeyString.Yearly_Enrollment, { previewID: "" }],
    });
    setShowLeaveWarningModal(false);
  };
  const onDiscardChanges = async () => {
    const resp = await EnrollmentTrackingSvc.discardChanges(previewID);
    console.log("discard", resp);
    // window.history.replaceState({}, "");
    toggleTable("");
    closeModal();
  };

  //Multiple tables can be opened
  // const toggleTable = (id: string) => {
  //   setVisibleTables((prev) => ({
  //     ...prev,
  //     [id]: !prev[id],
  //   }));
  // };

  //only single will open at a time
  const toggleTable = (id: string) => {
    setVisibleTables((prev) => {
      const isCurrentlyOpen = prev[id];
      return {
        [id]: !isCurrentlyOpen,
      };
    });
  };

  const [sliderValue, setSliderValue] = useState(15);

  const { data: enrollmentInfo, refetch: refetchEnrollmentInfo } =
    useYearlyEnrollments(state?.previewId ? state?.previewId : previewID);

  const updateVisibleMonth = () => {
    if (!calendarRef.current) return;

    const scrollLeft = calendarRef.current.scrollLeft;
    const clientWidth = calendarRef.current.clientWidth;

    const dayWidth = 65; //based on the width of the cells

    const startIndex = Math.floor(scrollLeft / dayWidth);
    const endIndex = Math.min(
      daysData.length - 1,
      Math.floor((scrollLeft + clientWidth) / dayWidth)
    );
    const visibleDays = daysData?.slice(
      Math.max(0, startIndex),
      Math.min(daysData.length, endIndex + 1)
    );

    if (visibleDays?.length > 0) {
      const currentMonth = visibleDays[0]?.month;
      const currentYear = visibleDays[0]?.year;
      const currentMonthYear = `${currentMonth}, ${currentYear}`;

      if (currentMonthYear !== visibleMonthYear) {
        setVisibleMonthYear(currentMonthYear);
      }
    }
  };

  // const getSlots = async () => {
  //   const res = await classroomSvc.fetchTransitionSlots(enrollmentInfo?.classrooms);
  // };

  useEffect(() => {
    const calendarContainer = calendarRef.current;

    if (calendarContainer) {
      calendarContainer.addEventListener("scroll", updateVisibleMonth);
      updateVisibleMonth(); // initial check on load
    }

    return () =>
      calendarContainer?.removeEventListener("scroll", updateVisibleMonth);
  }, [visibleMonthYear, daysData]);

  // console.log("enrollment tracking", enrollmentInfo);
  return (
    <DashboardWrapper>
      <div className="ps-6 pt-5 bg-secondaryNeutral">
        <Breadcrumb items={breadcrumbItems} />
        {enrollmentInfo && enrollmentInfo.skippedStudents?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Transition_In_Or_Out}
            skippedStudents={enrollmentInfo.skippedStudents}
          />
        )}

        {enrollmentInfo && enrollmentInfo.skippedStarts?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Start}
            skippedStudents={enrollmentInfo.skippedStarts}
          />
        )}

        {enrollmentInfo && enrollmentInfo.skippedWithDrawals?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Withdraw}
            skippedStudents={enrollmentInfo.skippedWithDrawals}
          />
        )}

        <div className="pe-6 mb-6">
          <EnrolTrackingOptimization
            switches={switches}
            handleToggle={handleToggle}
          />
          <AutoMovements
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            currentDayStarts={enrollmentInfo?.currentDayStarts}
            currentDayTransitions={enrollmentInfo?.currentDayTransitions}
            currentDayWithdrawals={enrollmentInfo?.currentDayWithDrawals}
            tomorrowStarts={enrollmentInfo?.tomorrowStarts}
            tomorrowTransitions={enrollmentInfo?.tomorrowTransitions}
            tomorrowWithdrawals={enrollmentInfo?.tomorrowWithDrawals}
            switches={switches}
            onDiscardChanges={onDiscardChanges}
            onCancel={handleToggle}
            showModal={showLeaveWarningModal}
            previewID={previewID}
            closeModal={closeModal}
          />
        </div>
        <div
          ref={calendarRef}
          className="relative overflow-x-auto custom-scrollbar h-[calc(100vh-300px)]"
        >
          <div className="table border-t-[0.5px] sticky top-0 z-[999]">
            <div className="w-[300px]  table-cell bg-white sticky left-0">
              <div className="bg-white ps-3 py-2 sticky left-0">
                <h2 className="text-base text-primary font-semibold">
                  {visibleMonthYear}
                </h2>
              </div>
              <div className="bg-primaryVariant2 flex items-center gap-[50px] px-3 py-1.5">
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Classroom
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Session
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Wait
                </h3>
              </div>
            </div>
            <div className="table-cell">
              <div className="flex flex-col">
                <EnrollmentCalendar daysData={daysData} />
                <div className="bg-primaryVariant2 py-[17.5px]"></div>
              </div>
            </div>
          </div>

          {enrollmentInfo?.classrooms?.map((classroom: Classroom, index) => {
            return (
              <div className="table border mb-6">
                <div className="w-[300px] min-w-[300px] bg-white sticky left-0 table-cell z-10">
                  <SessionTime classroom={classroom} />
                </div>
                <div className="table-cell bg-[#F1F8FF]">
                  <div className="">
                    {/* Full Time */}
                    <div className="flex gap-0 m-0 p-0 flex-1">
                      {classroom.dailyCounts.map((dailyCount) => {
                        const uniqueId = `${classroom.classroomId}-${dailyCount.date}-${index}`;

                        return (
                          <div key={uniqueId} className="w-[65px]">
                            <CalendarData
                              switches={switches}
                              toggleTable={() => toggleTable(uniqueId)}
                              dailyCounts={dailyCount}
                              classroom={classroom}
                            />
                            {visibleTables[uniqueId] && (
                              <div
                                // ref={containerRef}
                                onClick={(e) => e.stopPropagation()}
                                className={`${
                                  visibleTables[uniqueId] ? "scrollWrapper" : ""
                                }`}
                              >
                                <StudentEnrollmentTable
                                  students={[
                                    ...dailyCount.fullTimeStudents,
                                    ...dailyCount.partTimeStudents,
                                  ]}
                                  previewId={previewID}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {/* Part Time */}
                    {classroom.settingType === SessionType.FullAndPartTime && (
                      <div className="flex gap-0 m-0 p-0 flex-1">
                        {classroom.dailyCounts.map((dailyCount) => {
                          const uniqueId = `${classroom.classroomId}-${dailyCount.date}-${index}-${index}`;
                          return (
                            <div className="w-[65px]" key={uniqueId}>
                              <CalendarData
                                switches={switches}
                                toggleTable={() => toggleTable(uniqueId)}
                                dailyCounts={dailyCount}
                                classroom={classroom}
                                isPartTime={true}
                              />
                              {visibleTables[uniqueId] && (
                                <div
                                  // ref={containerRef}
                                  onClick={(e) => e.stopPropagation()}
                                  className={`${
                                    visibleTables[uniqueId]
                                      ? "scrollWrapper"
                                      : ""
                                  }`}
                                >
                                  <StudentEnrollmentTable
                                    students={[
                                      ...dailyCount.fullTimeStudents,
                                      ...dailyCount.partTimeStudents,
                                    ]}
                                    previewId={previewID}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {showLeaveWarningModal && (
          <WarningModal previewID={previewID} onClose={onDiscardChanges} />
        )}
      </div>
    </DashboardWrapper>
  );
};

export default EnrollmentTracking;
