import { useQuery } from "@tanstack/react-query";
import { StaffQueryKeyString } from "../../enums/staffQueryKeyString";
import { staffSvc } from "../../services/staff.service";

export const useStaff = () => {
  return useQuery({
    queryKey: [StaffQueryKeyString.Staff],
    queryFn: () => staffSvc.getStaff(),
  });
};
