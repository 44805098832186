import { useEffect } from "react";
import CcfHomeBanner from "../../../shared/components/ccfHomeBanner/CcfHomeBanner";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import TrustedCustomer from "../../../shared/components/trustedCustomer/TrustedCustomer";
import CanAchive from "../../components/canAchive/CanAchive";
import NewsAndResearch from "../../components/newsAndResearch/NewsAndResearch";

const CcfHomePage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <>
      <MainWrapper>
        <div>
          <CcfHomeBanner />
          <div className="container ">
            <div className="grid lg:grid-cols-12 xl:gap-12 lg:gap-4 md:gap-4 gap-4 py-6 md:py-12 lg:py-16">
              <div className="lg:col-span-8 flex flex-col">
                <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-3 text-start">
                  What can I Achieve?
                </h2>
                <p className="text-lg text-secondaryVariant mb-4 md:mb-9">
                  Bringing everyone together to create efficient and
                  cost-effective childcare.
                </p>

                <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-3 lg:gap-4 xl:gap-6 flex-1">
                  <CanAchive />
                </div>
              </div>
              <div className="lg:col-span-4">
                <NewsAndResearch />
              </div>
            </div>
          </div>
          {/* <FrequentlyQuestions /> */}
          {/* <BlogCards /> */}
          <TrustedCustomer />
        </div>
      </MainWrapper>
    </>
  );
};

export default CcfHomePage;
