import { useQuery } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";

export const useCategories = () => {
  return useQuery({
    queryKey: [TaskGenerationQueryKeyString.Category],
    queryFn: () => taskGenerationSvc.fetchCategories(),
  });
};
