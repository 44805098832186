import React, { useEffect, useState } from "react";
import { PiNotepadLight } from "react-icons/pi";
import { IoAlertCircleOutline } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { Tooltip } from "react-tooltip";

interface Task {
  hour: string; 
  status: 'success' | 'danger' | 'warning' | 'default';
}



const ForayTracking = () => {

  const tasks: Task[] = [
    { hour: '2 PM',  status: 'success' },
    { hour: '2 PM',  status: 'danger' },
    { hour: '2 PM',  status: 'warning' },
    { hour: '6 AM',  status: 'default' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '10 AM', status: 'success' },
    { hour: '12 PM',  status: 'default' },
  ];
    const hours: string[] = [
      '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM'
    ];
  
    const tasksByHour: Record<string, Task[]> = hours.reduce((acc, hour) => {
      acc[hour] = tasks.filter(task => task.hour === hour); 
      return acc;
    }, {} as Record<string, Task[]>);

    const getStatusClass = (status: 'success' | 'danger' | 'warning' | 'default') => {
      switch (status) {
        case 'success':
          return 'border-successAlert bg-successAlert';
        case 'danger':
          return 'border-dangerAlert bg-dangerAlert';
        case 'warning':
          return 'border-warningAlert bg-warningAlert';
        default:
          return 'border-gray-400 bg-gray-400'; 
      }
    };


    const [timeProgress, setTimeProgress] = useState<number>(0);

    useEffect(() => {
      const calculateTimeProgress = () => {
        const startOfDay = new Date();
        startOfDay.setHours(6, 0, 0, 0); 
  
        const endOfDay = new Date();
        endOfDay.setHours(18, 0, 0, 0); 
  
        const currentTime = new Date();
  
        if (currentTime < startOfDay || currentTime > endOfDay) {
          setTimeProgress(0);
          return;
        }
  
        const timePassed = currentTime.getTime() - startOfDay.getTime();
        const totalDayTime = endOfDay.getTime() - startOfDay.getTime();
  
        const progressPercentage = (timePassed / totalDayTime) * 100;
        setTimeProgress(progressPercentage);
      };
  
      calculateTimeProgress();
  
      const interval = setInterval(calculateTimeProgress, 60000); 
      return () => clearInterval(interval);
    }, []);
  return (
    <>
      <div className="bg-white rounded-xl p-5">
        <div className="flex items-center justify-between mb-20">
          <div className="flex item-center gap-2">
            <PiNotepadLight className="w-6 h-6 text-statusColor" />
            <h3 className="text-xl text-secondary font-semibold flex items-center gap-1">
              Tracking{" "}
              <IoAlertCircleOutline className="w-5 h-5 text-secondaryVariant" />
            </h3>
          </div>
          <h3 className="text-lg font-bold text-secondary">Thu Sep 3 ‘23</h3>
        </div>
        <div>
          <div className="flex items-end gap-14">
            <h3 className="text-secondary text-xl font-medium mb-8">Tasks</h3>
            <div className="grid grid-cols-12 flex-1 items-end pr-[50px]">
            {hours.map((hour) => (
          <div key={hour} className="flex items-end">
              {tasksByHour[hour].length > 0 ? (
                <ul className="mb-1  flex flex-wrap justify-between flex-col  max-h-[90px]">
                  {tasksByHour[hour].map((task, index) => (
                    <li  key={index} className="mb-[0.5px] relative group">
                  <span
                          className={`w-4 h-4 border-[3px] rounded-full block ${getStatusClass(
                            task.status
                          )}`}
                        ></span>
                        <div className="absolute left-0 top-full mt-2 text-black min-w-[308px]  opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 space-y-2 z-10">
                        <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]"></div>
                         <div className=" px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                          <div className="flex items-center justify-between gap-2">
                            <p className="text-base text-secondary">Category</p>
                            <p className="text-xs text-secondaryVariant">{task.hour}</p>
                          </div>
                          <p className="text-xs text-secondaryVariant">
                            Lorem ipsum dolor sit amet consectetur. Erat eleifend massa sit nunc imperdiet netus praesent.
                          </p>
                          <div className="flex items-end">
                            <div className="flex-1">
                              <h4 className="text-secondary">Initials</h4>
                              <input
                                type="text"
                                className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                              />
                            </div>
                            <button className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center">
                              Mark as Done
                            </button>
                          </div>
                        </div>
                        </div>
                    </li>
                  ))}
                </ul>
              ) : (
                '' 
              )}
          </div>
        ))}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-secondaryVariant font-semibold w-[120px]">
              6 AM
            </span>
            <div className="bg-secondaryNeutral rounded-[5px] h-[37px] w-full">
              <div
                className="relative bg-secondary rounded-[5px] h-[37px]"
                style={{ width: `${timeProgress}%` }}
              >
                <span className="text-sm text-white font-semibold absolute right-2 top-2">
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
            </div>
            <span className="text-sm text-secondaryVariant font-semibold w-12">
              6 PM
            </span>
          </div>
          <div className="flex items-start gap-[22px]">
            <h3 className="text-secondary text-xl font-medium mt-8">
              Checklist
            </h3>
            <div className="grid grid-cols-12 flex-1  pr-[50px]">
            {hours.map((hour) => (
              <div key={hour} className="flex mt-1">
                {tasksByHour[hour]?.length > 0 ? (
                  <ul className="mb-1 flex flex-wrap flex-col max-h-[90px]">
                    {tasksByHour[hour].map((task, index) => (
                      <li key={index} className="mb-[0.5px] relative group">
                        <span
                          className={`w-4 h-4 border-[3px] rounded-full block ${getStatusClass(
                            task.status
                          )}`}
                        ></span>
                        <div className="absolute left-0 top-full mt-2 text-black min-w-[308px]  opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 space-y-2 z-10">
                        <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]"></div>
                         <div className=" px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                          <div className="flex items-center justify-between gap-2">
                            <p className="text-base text-secondary">Category</p>
                            <p className="text-xs text-secondaryVariant">{task.hour}</p>
                          </div>
                          <p className="text-xs text-secondaryVariant">
                            Lorem ipsum dolor sit amet consectetur. Erat eleifend massa sit nunc imperdiet netus praesent.
                          </p>
                          <div className="flex items-end">
                            <div className="flex-1">
                              <h4 className="text-secondary">Initials</h4>
                              <input
                                type="text"
                                className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                              />
                            </div>
                            <button className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center">
                              Mark as Done
                            </button>
                          </div>
                        </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ))}

            </div>
            {/* <ul className="mt-1 space-y-0.5">
              <li>
                <span className="w-4 h-4 border-[3px] border-successAlert rounded-full bg-successAlert block"></span>
              </li>
              <li>
                <span className="w-4 h-4 border-[3px] border-successAlert rounded-full bg-successAlert block "></span>
              </li>
              <li>
                <span className="w-4 h-4 border-[3px] border-dangerAlert bg-dangerAlert rounded-full block "></span>
              </li>
              <li>
                <span className="w-4 h-4 border-[3px] border-statusColor rounded-full block bg-statusColor"></span>
              </li>
              <li>
                <button className="w-4 h-4 border-[3px] bg-statusColor border-statusColor rounded-full text-center flex items-center justify-center">
                  <GoPlus className="text-white w-3.5 h-3.5" />
                </button>
              </li>
              <li>
                <button className="w-4 h-4 border-[3px] bg-statusColor border-statusColor rounded-full text-center flex items-center justify-center">
                  <IoIosClose className="text-white w-3.5 h-3.5" />
                </button>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForayTracking;
