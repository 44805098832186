import React, { useState } from "react";
import { PiCalendarPlus } from "react-icons/pi";
import { TbCamera } from "react-icons/tb";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import {
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../shared/utils/formatDate";
import { showInfoToast, showSuccessToast } from "../../../shared/utils/toast";
import { useCreateStaffMutation } from "../../hooks/mutations/useCreateStaffMutation";
import { IStaffCreate } from "../../types/IStaff";
import { postFiles } from "../../../shared/services/auth.service";
import { staffMessage } from "../../constants/toastMessages";

const EmployeeInformationForm = () => {
  const { mutate } = useCreateStaffMutation();
  const [employeeInfo, setEmployeeInfo] = useState<IStaffCreate | undefined>();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const validateEmployeeInfo = (info: IStaffCreate | undefined): string[] => {
    const errors: string[] = [];

    if (!info?.personalInformation) {
      errors.push("Please fill out the form");
      return errors;
    }

    const { firstName, lastName, email, mobileNumber } =
      info.personalInformation;

    // First Name validation
    if (!firstName?.trim()) {
      errors.push("First name is required");
    } else if (firstName.length < 2) {
      errors.push("First name must be at least 2 characters long");
    } else if (firstName.length > 50) {
      errors.push("First name must not exceed 50 characters");
    }

    // Last Name validation
    if (!lastName?.trim()) {
      errors.push("Last name is required");
    } else if (lastName.length < 2) {
      errors.push("Last name must be at least 2 characters long");
    } else if (lastName.length > 50) {
      errors.push("Last name must not exceed 50 characters");
    }

    // Email validation
    if (!email?.trim()) {
      errors.push("Email is required");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.push("Please enter a valid email address");
      }
    }

    // Mobile Number validation
    if (!mobileNumber?.trim()) {
      errors.push("Mobile number is required");
    } else {
      // Validates phone numbers with optional country code
      // const phoneRegex = /^\+?[1-9]\d{9,14}$/;
      // if (!phoneRegex.test(mobileNumber.replace(/[\s-]/g, ""))) {
      //   errors.push("Please enter a valid mobile number");
      // }
    }

    return errors;
  };

  const handleSave = async () => {
    if (employeeInfo) {
      const validationErrors = validateEmployeeInfo(employeeInfo);

      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => showInfoToast(error));
        return;
      }

      try {
        // First upload the file if exists
        if (selectedFile) {
          const formData = new FormData();

          formData.append("files", selectedFile);

          const uploadResponse: any = await postFiles(formData);

          if (uploadResponse?.ok) {
            setEmployeeInfo((prev) =>
              prev
                ? {
                    ...prev,
                    personalInformation: {
                      ...prev.personalInformation,
                      profilePictureUrl: uploadResponse.data.urls[0],
                    },
                  }
                : undefined
            );
          }
        }

        mutate({ personInfo: employeeInfo });
      } catch (error) {
        showInfoToast(staffMessage.Upload_File_Error);
        console.error("Upload error:", error);
      }
    }
  };

  const stateOptions = [
    { value: "Punjab", label: "Punjab" },
    { value: "Sindh", label: "Sindh" },
    { value: "KPK", label: "KPK" },
    { value: "Balochistan", label: "Balochistan" },
  ];

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const citizenshipOptions = [
    { value: "Pakistan", label: "Pakistan" },
    { value: "United States of America", label: "United States of America" },
  ];

  const cityOptions = [
    { value: "Lahore", label: "Lahore" },
    { value: "Islamabad", label: "Islamabad" },
  ];

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check file size
      if (file.size > 1 * 1024 * 1024) {
        showInfoToast(staffMessage.Invalid_File_Size);
        return;
      }

      // Check file type
      if (!file.type.match("image.*")) {
        showInfoToast(staffMessage.Select_Image);
        return;
      }

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePreview = e.target?.result as string;
        setImagePreview(imagePreview);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Fill Out The Information
        </h2>
        <div className="grid grid-cols-12 gap-9">
          <div className="col-span-3">
            <div className="bg-white rounded-xl space-y-3">
              <img
                className="w-[218px] h-[228px] rounded-lg border-secondaryVariant2 border-[0.3px]"
                src={`${
                  imagePreview ??
                  " https://a360csastorage.blob.core.windows.net/childcare/8669bd41-eb7e-48c2-b750-e7cfdff8cbde-fileName-dummyStudent-img.png"
                }`}
                alt="student"
              />

              <div className="text-start">
                <label className="text-base text-white bg-secondary border-[0.5px] border-secondaryVariant2 rounded-lg py-3 px-4 cursor-pointer flex items-center justify-center gap-2 max-w-[218px]">
                  <TbCamera className="w-6 h-6" />
                  Upload Photo{" "}
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-span-9">
            <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  First Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.firstName}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        firstName: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Middle Name{" "}
                </label>
                <input
                  value={employeeInfo?.personalInformation.middleName}
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        middleName: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Last Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.lastName}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        lastName: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Preferred Name{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.preferredName}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        preferredName: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Email*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.email}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        email: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Mobile Number*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.mobileNumber}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        mobileNumber: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Date of Birth
                </label>
                <div className="w-full relative">
                  <ReactDatePicker
                    value={revertToDateType(
                      employeeInfo?.personalInformation?.dateOfBirth as string
                    )}
                    placeholder="MM/DD/YYYY"
                    maxDate={new Date()}
                    onChange={(date) =>
                      setEmployeeInfo((prev: any) => ({
                        ...prev,
                        personalInformation: {
                          ...prev?.personalInformation,
                          dateOfBirth: formatDateSlashFullYear(date as Date),
                        },
                      }))
                    }
                  />
                  <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                </div>
              </div>
              <div>
                <ReactSelect
                  options={genderOptions}
                  label="Gender"
                  placeholder="Gender "
                  required
                  onChange={(value) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        gender: value?.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="space-y-9 mb-9">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  SSN (Social Security Number){" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.ssn}
                  onChange={(e) => {
                    // Remove all non-numeric characters
                    const cleaned = e.target.value.replace(/\D/g, "");

                    // Limit to 9 digits
                    const trimmed = cleaned.slice(0, 9);

                    // Format as XXX-XX-XXXX
                    let formatted = trimmed;
                    if (trimmed.length > 5) {
                      formatted = `${trimmed.slice(0, 3)}-${trimmed.slice(
                        3,
                        5
                      )}-${trimmed.slice(5)}`;
                    } else if (trimmed.length > 3) {
                      formatted = `${trimmed.slice(0, 3)}-${trimmed.slice(3)}`;
                    }

                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        ssn: formatted,
                      },
                    }));
                  }}
                  maxLength={11} // 9 digits + 2 hyphens
                  placeholder="XXX-XX-XXXX"
                />

                {/* <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.ssn}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        ssn: e.target.value,
                      },
                    }))
                  }
                /> */}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Current Street Address{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={employeeInfo?.personalInformation.address}
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        address: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
              <div>
                <ReactSelect
                  label="Citizenship"
                  placeholder="Citizenship "
                  required
                  options={citizenshipOptions}
                  onChange={(value) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        citizenShip: value?.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <ReactSelect
                  label="City"
                  placeholder="City "
                  required
                  options={cityOptions}
                  onChange={(value) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        city: value?.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <ReactSelect
                  label="State"
                  placeholder="State "
                  required
                  options={stateOptions}
                  onChange={(value) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        state: value?.value,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Zip Code{" "}
                </label>
                <input
                  value={employeeInfo?.personalInformation.postalCode}
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  onChange={(e) =>
                    setEmployeeInfo((prev: any) => ({
                      ...prev,
                      personalInformation: {
                        ...prev?.personalInformation,
                        postalCode: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button onClick={handleSave} className="btnPrimary max-w-[181px]">
                Save
              </button>
              <button className="btnSimple max-w-[181px]">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInformationForm;
