import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { TbUserX } from "react-icons/tb";
import { IEmployeeInfo } from "../../types/IStaff";
import TerminateDialog from "../TerminateDialog";
import { forayRoutes } from "../../../foray_module/routes/routes";
import { useNavigate } from "react-router-dom";

type Person = {
  image: string;
  employeeName: string;
  employeeId: string;
  classRoom: string;
  jobTitle: string;
  session: string;
  action: string;
};

const defaultData: Person[] = [
  {
    image:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=32",
    employeeName: "Saige Fuentes",
    employeeId: "00614650",
    classRoom: "Infant",
    jobTitle: "UI UX Designer",
    session: "Full-time",
    action: "",
  },
  {
    image: "https://eu.ui-avatars.com/api/?name=John+Doe&size=32",
    employeeName: "John Doe",
    employeeId: "00614651",
    classRoom: "Toddler",
    jobTitle: "Graphic Designer",
    session: "Part-time",
    action: "",
  },
  {
    image: "https://eu.ui-avatars.com/api/?name=John+Doe&size=32",
    employeeName: "John Doe",
    employeeId: "00614651",
    classRoom: "Toddler",
    jobTitle: "Graphic Designer",
    session: "Terminated",
    action: "",
  },
  {
    image: "https://eu.ui-avatars.com/api/?name=John+Doe&size=32",
    employeeName: "John Doe",
    employeeId: "00614651",
    classRoom: "Toddler",
    jobTitle: "Graphic Designer",
    session: "Terminated",
    action: "",
  },
  {
    image: "https://eu.ui-avatars.com/api/?name=John+Doe&size=32",
    employeeName: "John Doe",
    employeeId: "00614651",
    classRoom: "Toddler",
    jobTitle: "Graphic Designer",
    session: "",
    action: "",
  },
];

type EmployeeCurrentProps = {
  employees: IEmployeeInfo[];
  filterTerminated?: boolean;
  showEmptySession?: boolean;
};

const columnHelper = createColumnHelper<IEmployeeInfo>();

const EmployeeCurrent = ({
  filterTerminated,
  showEmptySession,
  employees,
}: EmployeeCurrentProps) => {
  const navigate = useNavigate();
  const filteredData = showEmptySession
    ? employees.filter((person) => !person.professionalInformation.session)
    : filterTerminated
    ? employees.filter(
        (person) => person.professionalInformation.session === "Terminated"
      )
    : employees.filter(
        (person) =>
          person.professionalInformation.session &&
          person.professionalInformation.session !== "Terminated"
      );

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const shouldDisplaySessionColumn = filteredData.some(
    (person) => person.professionalInformation.session
  );

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleViewDetails = (employee: IEmployeeInfo) => {
    navigate(forayRoutes.employee_details, {
      state: { employee: employee },
    });
  };

  const columns = [
    columnHelper.accessor("personalInformation.preferredName", {
      id: "employeeName",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Employee Name</span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full inline-flex items-center">
            <img
              src={info.row.original.personalInformation.profilePictureUrl}
              alt=""
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <span className="flex-1 line-clamp-1">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("professionalInformation.employeeId", {
      id: "employeeId",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Employee Id</span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("professionalInformation.classroom", {
      id: "classRoom",
      header: () => (
        <div className="text-center">
          <span>Classroom</span>
        </div>
      ),
      cell: (info) => <div className="text-center">{info.getValue()}</div>,
    }),
    columnHelper.accessor("professionalInformation.jobTitle", {
      id: "jobTitle",
      header: () => (
        <div className="text-center">
          <span>Job Title </span>
        </div>
      ),
      cell: (info) => <div className="text-center">{info.getValue()}</div>,
    }),
    ...(shouldDisplaySessionColumn
      ? [
          columnHelper.accessor("professionalInformation.session", {
            id: "session",
            header: "Session",
            cell: (info) => (
              <div
                className={`inline-block px-3 py-1 rounded ${
                  info.getValue() === "Full-time"
                    ? "bg-green-100 text-green-500"
                    : info.getValue() === "Part-time"
                    ? "bg-yellow-100 text-yellow-500"
                    : "bg-red-500 text-white"
                }`}
              >
                {info.getValue()}
              </div>
            ),
          }),
        ]
      : []),
    columnHelper.display({
      id: "action",
      header: () => <span className="block text-end">Action</span>,
      cell: (info) =>
        info.row.original.professionalInformation.session === "Terminated" ? (
          <div className="block text-end">
            <button className="inline-flex items-center gap-2 text-blue-500">
              <AiOutlineEye size={24} />
            </button>
          </div>
        ) : (
          <div className="text-end">
            <Dropdown className="relative inline-block menuRight">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <BsThreeDotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu className="flex items-center flex-col dropDown left-auto bg-white z-10 rounded-lg p-3 shadow-[-21px_25px_29.8px_0px_rgba(0,0,0,0.06)] min-w-[253px] space-y-3">
                <Dropdown.Item
                  onClick={() => handleViewDetails(info.row.original)}
                  // href={forayRoutes.employee_personal_information}
                  className="flex items-center gap-2"
                >
                  <AiOutlineEye className="text-secondaryVariant2" />
                  <span>View Details</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  className="flex items-center gap-2"
                  onClick={handleOpenDialog}
                >
                  <RiLogoutCircleRLine className="text-secondaryVariant2" />
                  <span>Terminate Employee</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
    }),
  ];

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div>
        <table className="w-full bg-white rounded-t-lg">
          <thead className="sticky top-0 bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-5 text-left text-base font-medium text-secondary capitalize"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-secondaryNeutral">
            {table.getRowModel().rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                className={`${rowIndex % 2 === 0 ? "bg-secondaryNeutral" : ""}`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-5 text-base text-secondaryVariant border-b border-secondaryNeutral"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TerminateDialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
        <div className="text-center space-y-6">
          <TbUserX size={60} className="inline-block text-red-500" />
          <h4 className="text-center text-2xl text-secondary font-semibold">
            Are you sure you want to Terminate this Employee?
          </h4>
        </div>
      </TerminateDialog>
    </>
  );
};

export default EmployeeCurrent;
