import React, { useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";

interface CustomCheckboxProps {
  checked?: boolean;
  text?: string;
  onChange?: (checked: boolean) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked = false, onChange ,text}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (onChange) {
      onChange(newCheckedState);
    }
  };

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  return (
    <label className="inline-flex gap-3 items-center mt-1.5">
      <div className="relative leading-none">
        <input
          type="checkbox"
          className="appearance-none h-5 w-5 border border-secondaryVariant2 rounded-md focus:outline-none cursor-pointer"
          checked={isChecked}
          onChange={handleChange}
        />
        {/* Icon container */}
        <div
          className={`absolute inset-0 flex items-center justify-center ${isChecked ? "bg-secondaryVariant h-5 w-5" : "bg-transparent"
            } rounded-md pointer-events-none`}
        >
          {isChecked && <HiCheck className="text-white w-3 h-3" />}
        </div>
      </div>
      <span className="text-base text-secondaryVariant  ">{text}</span>
    </label>
  );
};

export default CustomCheckbox;
