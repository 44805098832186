import { useCallback, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { LiaUserSolid } from "react-icons/lia";
import { PiUsersThree } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../../../foray_module/routes/routes";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../components/Breadcrumb";
import CardHrDashboard from "../../components/CardHrDashboard";
import FilterButton from "../../components/FilterButton";
import FilterTabs from "../../components/FilterTabs";
import EmployeeCurrent from "../../components/tables/EmployeeCurrent";
import { useStaff } from "../../hooks/queries/useStaff";

const HrDashboard = () => {
  const [selectedTile, setSelectedTile] = useState(0);
  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "Human Resource Management", link: forayRoutes.hr_dashboard },
    { label: "Employee Setup", isCurrent: true },
  ];
  const cardLinks = [
    { id: 0, title: "Dashboard-HR", icon: <HiOutlineViewGridAdd size={26} /> },
    { id: 1, title: "Staff Operations", icon: <PiUsersThree size={26} /> },
    { id: 2, title: "Employee View/Setup", icon: <LiaUserSolid size={26} /> },
  ];
  const handleTileClick = useCallback(
    (id: number) => {
      if (id !== selectedTile) {
        console.log("Clicked ID:", id);
        setSelectedTile(id);
      }
    },
    [selectedTile]
  );

  const handleAddEmployee = () => {
    navigate(forayRoutes.employee_personal_information);
  };

  const { data: employees } = useStaff();

  return (
    <DashboardWrapper>
      <div className="px-6 py-8 space-y-6">
        <Breadcrumb items={breadcrumbItems} />
        <h2 className="text-xl font-semibold text-secondary">
          Human Resource Management
        </h2>
        <div className="grid grid-cols-3 gap-6">
          {cardLinks.map((link, index) => (
            <CardHrDashboard
              onTileClick={handleTileClick}
              key={link.id}
              title={link.title}
              icon={link.icon}
              selectedTile={selectedTile ?? -1}
              id={link.id}
            />
          ))}
        </div>

        {selectedTile === 1 && <FilterTabs />}

        {selectedTile === 2 && (
          <>
            <h4 className=" text-secondary font-semibold text-xl">Details</h4>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="flex items-center border-b border-secondaryVariant2 mb-4"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    className="inline-flex items-center p-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                  >
                    Current
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className="inline-flex items-center p-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                  >
                    Terminated
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="third"
                    className="inline-flex items-center p-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                  >
                    Prospective
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first"
                  className="hidden opacity-0 [&.show]:block [&.show]:opacity-100  duration-300 transition-opacity ease-linear"
                >
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <button
                        onClick={handleAddEmployee}
                        className="flex items-center text-white text-lg font-medium
                      gap-1 bg-primary px-3 py-3 rounded-md"
                      >
                        Add Employee <GoPlus />
                      </button>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="relative flex-1">
                        <input
                          type="text"
                          className="w-full pl-[36px_!important] border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                          placeholder="Search"
                          name="Search"
                        />
                        <CiSearch
                          size={24}
                          className="absolute top-1/2 left-2 -translate-y-1/2 text-secondaryVariant"
                        />
                      </div>
                      <FilterButton />
                    </div>
                  </div>
                  <h4 className="my-8 text-secondary font-semibold text-xl">
                    Current Employees
                  </h4>
                  <EmployeeCurrent
                    employees={employees?.data}
                    filterTerminated={false}
                    showEmptySession={false}
                  />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                >
                  <div className="grid grid-cols-2 gap-6 mb-4">
                    <div>
                      <h4 className="text-secondary font-semibold text-xl">
                        Terminated Employees
                      </h4>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="relative flex-1">
                        <input
                          type="text"
                          className="w-full pl-[36px_!important] border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                          placeholder="Search"
                          name="Search"
                        />
                        <CiSearch
                          size={24}
                          className="absolute top-1/2 left-2 -translate-y-1/2 text-secondaryVariant"
                        />
                      </div>
                      <FilterButton />
                    </div>
                  </div>

                  <EmployeeCurrent
                    employees={employees?.data}
                    filterTerminated={true}
                    showEmptySession={false}
                  />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="third"
                  className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                >
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <button
                        className="flex items-center text-white text-lg font-medium
                      gap-1 bg-primary px-3 py-3 rounded-md"
                      >
                        Add Prospective <GoPlus />
                      </button>
                    </div>
                    <div className="flex gap-4 items-center">
                      <div className="relative flex-1">
                        <input
                          type="text"
                          className="w-full pl-[36px_!important] border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                          placeholder="Search"
                          name="Search"
                        />
                        <CiSearch
                          size={24}
                          className="absolute top-1/2 left-2 -translate-y-1/2 text-secondaryVariant"
                        />
                      </div>
                      <FilterButton />
                    </div>
                  </div>
                  <h4 className="my-8 text-secondary font-semibold text-xl">
                    Prospective
                  </h4>
                  <EmployeeCurrent
                    employees={employees?.data}
                    filterTerminated={false}
                    showEmptySession={true}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>
        )}
      </div>
    </DashboardWrapper>
  );
};

export default HrDashboard;
