import { Nav } from "react-bootstrap";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import TaskGenCard from "../../components/taskGenerationComponents/TaskGenCard";
import { forayRoutes } from "../../routes/routes";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import FillTaskInformation from "../../components/taskGenerationComponents/FillTaskInformation";
import AddCategoryModal from "../../components/modals/AddCategoryModal";
import React, { useState } from "react";
import TaskGenerationListing from "../../components/taskGenerationComponents/TaskGenerationListing";
import { useCategories } from "../../hooks/useCateogories";
import { ICategory } from "../../types/ICategories.interface";

const items = [
  { id: "1", title: "Starts", time: "10:00AM" },
  { id: "2", title: "Starts", time: "11:00AM" },
  { id: "3", title: "Starts", time: "12:00PM" },
  { id: "4", title: "Starts", time: "01:00PM" },
];

const navItems = [
  { id: "1", label: "Start Day" },
  { id: "2", label: "Verify Start" },
  { id: "3", label: "Withdrawals" },
  { id: "4", label: "Academic & FollowUp" },
  { id: "5", label: "Academic Reports" },
  { id: "6", label: "Anniversary" },
  { id: "7", label: "Verify Start" },
  { id: "8", label: "Withdrawals" },
  { id: "9", label: "Academic Reports" },
  { id: "10", label: "Start Day" },
];

const TaskGeneration = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("")
  const [selectedCard, setSelectedCard] = useState<ICategory>();
  const [selectedSection, setSelectedSection] = useState("");

  const { data: Categories } = useCategories();

  const openModal = (modalType: string) => {
    setModalType(modalType)
    setModalOpen(true)
  }
  const closeModal = () => {
    setSelectedCard(undefined)
    setModalType("")
    setModalOpen(false)
  }

  const breadcrumbItems = [
    { label: "Task Generation", link: forayRoutes.foray_screen },
    { label: "Starts", isCurrent: true },
  ];
  console.log("check categories: ", Categories?.data)
  const handleSelectedCard = (selectedCardId: string) => {
    const selectedCategory = Categories?.data?.find(
      (category) => category._id === selectedCardId
    );
    setSelectedCard(selectedCategory);
  };

  const handleSelectedSection = (sectionId: string) => {
    setSelectedSection(sectionId);
  };

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <div className="flex items-center justify-between mb-5 mt-10">
            <h2 className="text-xl text-secondary font-semibold">
              Task Generation
            </h2>
            <button className="btnPrimary max-w-[192px]" onClick={() => { openModal("add") }}>
              Add Category <HiOutlinePlusSmall className="w-5 h-5" />
            </button>
            <AddCategoryModal isOpen={isModalOpen} onClose={closeModal} modalType={modalType} selectedCard={selectedCard as  ICategory}/>
          </div>
          <TaskGenCard
            items={Categories?.data}
            handleSelected={handleSelectedCard}
            openModal={openModal}
          />
          <div className="flex items-end gap-2 my-6">
            <Nav
              activeKey="/0"
              variant="pills"
              onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
              className="flex items-center  w-full overflow-x-auto scrollHide"
            >
              {Categories?.data &&
                Categories.data
                  .find((item) => item._id === selectedCard?._id)
                  ?.sections.map((section) => (
                    <div
                      onClick={() => handleSelectedSection(section._id)}
                      key={section._id}
                      className=""
                    >
                      <div className="inline-flex whitespace-nowrap items-center px-3 pb-2 [&.active]:text-primary [&.active]:text-xl text-lg text-secondaryVariant [&.active]:font-bold border-b-[2px] border-secondaryVariant2 gap-1 [&.active]:border-primary hover:border-primary hover:text-primary">
                        {section.title}
                        <PiDotsThreeVerticalBold className="w-6 h-6" />
                      </div>
                    </div>
                  ))}
            </Nav>
            <button className="bg-primary p-1.5 rounded text-white">
              <HiOutlinePlusSmall className="w-7 h-7" />
            </button>
          </div>
          <FillTaskInformation />
          <TaskGenerationListing />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default TaskGeneration;
