import { useLocation, useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../components/Breadcrumb";
import PersonalInfo from "../../components/PersonalInfo";
import ProfileCard from "../../components/ProfileCard";
import UploadDocuments from "../../components/UploadDocuments";
import {
  EducationalInformation,
  IEmployeeInfo,
  PersonalInformation,
  ProfessionalInformation,
  UploadedDocuments,
} from "../../types/IStaff";
import { forayRoutes } from "../../../foray_module/routes/routes";
import { EditType } from "../../enums/editType";

const ProfileInformation = () => {
  const { state } = useLocation();
  const { employee } = state as { employee: IEmployeeInfo };
  const navigate = useNavigate();
  const convertToUserData = (personalInfo: PersonalInformation) => {
    const userData = [
      { label: "First Name", value: personalInfo.firstName },
      { label: "Middle Name", value: personalInfo.middleName },
      { label: "Last Name", value: personalInfo.lastName },
      { label: "Preferred Name", value: personalInfo.preferredName },
      { label: "Email", value: personalInfo.email },
      { label: "Phone Number", value: personalInfo.mobileNumber },
      { label: "Date of Birth", value: personalInfo.dateOfBirth },
      { label: "Gender", value: personalInfo.gender },
      { label: "SSN Number", value: personalInfo.ssn },
      { label: "Current Street Address", value: personalInfo.address },
      { label: "Citizenship", value: personalInfo.citizenShip },
      { label: "City", value: personalInfo.city },
      { label: "State", value: personalInfo.state },
      { label: "ZipCode", value: personalInfo.postalCode },
    ];

    return userData;
  };

  const convertToEmpData = (professionalInfo: ProfessionalInformation) => {
    const empData = [
      { label: "Employee Id", value: professionalInfo.employeeId || "N/A" },
      { label: "Start Date", value: professionalInfo.startTime || "N/A" },
      { label: "Job Title", value: professionalInfo.jobTitle || "N/A" },
      { label: "Department", value: professionalInfo.jobTitle || "N/A" },

      {
        label: "Full/Part Time",
        value: professionalInfo.session || "N/A",
      },
      { label: "Start Time", value: professionalInfo.startTime || "N/A" },
      { label: "End Time", value: professionalInfo.endTime || "N/A" },
      { label: "Working Days", value: professionalInfo.days || "N/A" },
      { label: "Salary Type", value: professionalInfo.salaryType || "N/A" },
      {
        label: "Salary Amount",
        value: professionalInfo.salaryAmount
          ? `$${professionalInfo.salaryAmount}`
          : "N/A",
      },
    ];
    return empData;
  };

  const convertToEduData = (educationalInfo: EducationalInformation) => {
    const eduData = [
      {
        label: "Educational Level",
        value: educationalInfo.education[0]?.educationLevel || "N/A",
      },
      {
        label: "University",
        value: educationalInfo.education[0]?.university || "N/A",
      },
      {
        label: "Certificate",
        value: educationalInfo.certificates[0]?.certificate || "N/A",
      },
      {
        label: "CEU Units",
        value: educationalInfo.requiredCEUPointsPerYear || "N/A",
      },
      {
        label: "Organization",
        value: educationalInfo.certificates[0]?.issuingOrganization || "N/A",
      },
    ];
    return eduData;
  };

  const convertToDocData = (uploadedDocs: UploadedDocuments) => {
    const docData = [
      {
        label: "Identity Documents",
        value: uploadedDocs.identityDocuments[0]?.name || "No document",
      },
      {
        label: "Educational Documents",
        value: uploadedDocs.educationDocuments[0]?.name || "No document",
      },
      {
        label: "Signed Agreement",
        value: uploadedDocs.signedAgreement[0]?.name || "No document",
      },
      {
        label: "Other Documents",
        value: uploadedDocs.otherDocuments[0]?.name || "No document",
      },
    ];
    return docData;
  };

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "Human Resource Management", link: forayRoutes.hr_dashboard },
    { label: "Employee Setup", isCurrent: true },
  ];

  const handleEditClick = (editType: string) => {
    navigate(forayRoutes.employee_personal_information, {
      state: { type: editType, employee: employee },
    });
  };

  return (
    <DashboardWrapper>
      <div className="px-6 py-8 space-y-6">
        <Breadcrumb items={breadcrumbItems} />
        <h2 className="text-xl font-semibold text-secondary">
          Employee Details
        </h2>
        <ProfileCard
          profileUrl={employee.personalInformation.profilePictureUrl}
        />
        <PersonalInfo
          personalInfo={convertToUserData(employee.personalInformation)}
          title={"Personal Info"}
          buttonLabel={"Edit Information"}
          onEdit={() => handleEditClick(EditType.personalInfo)}
        />
        <PersonalInfo
          personalInfo={convertToEduData(employee.educationalInformation)}
          title={"Education & Certification"}
          buttonLabel={"Edit Information"}
          onEdit={() => handleEditClick(EditType.education)}
        />
        <PersonalInfo
          personalInfo={convertToEmpData(employee.professionalInformation)}
          title={"Professional Info"}
          buttonLabel={"Edit Information"}
          onEdit={() => handleEditClick(EditType.professionalInfo)}
        />
        <UploadDocuments
          uploadDocuments={convertToDocData(employee.uploadedDocuments)}
          title={"Documents"}
          buttonLabel={"Edit Information"}
          onEdit={() => handleEditClick(EditType.documents)}
        />
      </div>
    </DashboardWrapper>
  );
};

export default ProfileInformation;
