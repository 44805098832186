import { FaRegEdit } from "react-icons/fa";

const PersonalInfo = ({ title, buttonLabel, personalInfo, onEdit }:any) => {
  return (
    <div className='bg-white rounded-xl gap-6'>
      <div className="flex items-center justify-between py-3 px-6">
        <h2 className="text-xl font-medium">{title}</h2>
        <button onClick={onEdit} className="inline-flex items-center gap-3 text-info">
          {buttonLabel} <FaRegEdit size={20} />
        </button>
      </div>
      <div className="flex flex-wrap">
        {personalInfo.map((item:any, index:any) => (
          <div
            key={index}
            className="w-1/2 grid grid-cols-3 gap-3 text-lg font-normal py-4 px-6 text-secondary border-t border-black/10"
          >
            <div className="text-secondary/70">
              {item.label}
            </div>
            <div className="col-span-2">
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalInfo;
