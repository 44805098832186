import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ForayDashboardCard from "../../components/forayDashboardComponents/ForayDashboardCard";
import ForayTracking from "../../components/forayDashboardComponents/ForayTracking";

const ForayDashboard = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="p-5">
          <ForayDashboardCard />
          <ForayTracking />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ForayDashboard;
