import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import useOutsideClick from "../../hooks/useOutsideClick";
import { sharedRoutes } from "../../routes/routes";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { medEvalRoutes } from "../../../medEvaluation_module/routes/routes";
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { STORAGE_KEYS } from "../../enums/sharedEnums";
const tabs = {
  home: ccfRoutes.home,
  about: sharedRoutes.about_us,
  services: "Services",
  contact: sharedRoutes.contact_us,
  blogs: sharedRoutes.blogs_screen,
  consultation: sharedRoutes.coming_soon,
  proffer: profferRoutes.home,
  adventures: adventureRoutes.home,
  medEval: medEvalRoutes.eval_center_home,
  foray: sharedRoutes.coming_soon,
};

const PrimaryHeader: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs.home);
  // const svcMenu = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  // useOutsideClick(svcMenu, () => setIsOpen(false));

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleTabClick = (tab: string, route?: string) => {
    setSelectedTab(tab);
    localStorage.setItem(STORAGE_KEYS.SELECTED_TAB, tab);
    if (route) navigate(route);
  };

  return (
    <div className="bg-white">
      <div className="container flex flex-row py-3 justify-between items-center">
        <Link to="/">
          <img
            src="https://a360csastorage.blob.core.windows.net/childcare/web-logo.png"
            alt="web logo"
            className="min-w-[126px]"
          />
        </Link>

        {/* Mobile menu toggle button */}
        <button
          className="xl:hidden text-2xl"
          onClick={toggleMobileMenu}
          aria-label="Toggle Mobile Menu"
        >
          {isMobileMenuOpen ? <IoClose /> : <IoReorderThreeOutline />}
        </button>

        {/* Main Navigation */}

        <nav
          className={`fixed top-0 left-0 h-full w-64 xl:w-full bg-white z-20 transform transition-transform duration-300 ease-in-out ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } xl:static xl:translate-x-0 xl:flex xl:flex-row xl:items-center xl:gap-7 xl:justify-end`}
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-200 xl:hidden">
            <Link to="/">
              <img
                src="https://a360csastorage.blob.core.windows.net/childcare/web-logo.png"
                alt="web logo"
                className="min-w-[126px]"
              />
            </Link>
            <button
              className="text-2xl"
              onClick={toggleMobileMenu}
              aria-label="Close Mobile Menu"
            >
              <IoClose />
            </button>
          </div>

          <ul className="flex flex-col xl:flex-row items-center gap-4 xl:gap-7 mt-8 xl:mt-0 p-4 xl:p-0">
            <li>
              <button
                // className="text-lg text-[#000000] active:font-semibold active:text-primary hover:text-primary px-3"
                className={`text-lg hover:text-primary px-3 ${
                  pathName === tabs.home
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.home, ccfRoutes.home);
                }}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className={`text-lg hover:text-primary px-3 ${
                  pathName === tabs.about
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.about, sharedRoutes.about_us);
                }}
              >
                About us
              </button>
            </li>
            <li className="hidden xl:block">
              <div
                className="relative group"
                id="dropdown-menu"
                onMouseLeave={() => {
                  setSelectedTab("");
                }}
              >
                <div
                  className="inline-flex items-center overflow-hidden "
                  onMouseOver={() => {
                    handleTabClick(tabs.services);
                  }}
                >
                  <button
                    id="menu-toggle-button"
                    className="flex items-center text-gray-600 hover:text-gray-700"
                  >
                    <span
                      className={`text-lg hover:text-primary px-3 ${
                        selectedTab === tabs.services
                          ? "text-primary font-semibold"
                          : "text-[#000000]"
                      }`}
                    >
                      Services
                    </span>
                    <span className="sr-only">Menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  id="menu-items"
                  className="absolute end-0 z-10 w-max rounded-md border border-gray-100 bg-white shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300"
                  role="menu"
                  // ref={svcMenu}
                  onMouseLeave={() => {
                    setSelectedTab("");
                  }}
                >
                  <div className="p-2">
                    <Link
                      to={adventureRoutes.home}
                      id="view-storefront"
                      className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                      role="menuitem"
                    >
                      Adventures
                    </Link>
                    <Link
                      to={medEvalRoutes.eval_center_home}
                      id="view-storefront"
                      className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                      role="menuitem"
                    >
                      Medical Evaluation
                    </Link>
                    {/* <Link
                        to={profferRoutes.home}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        PrOffer
                      </Link>
                      <button
                        onClick={() => {
                          handleNavToInBuild("Foray");
                          setIsOpen(false);
                        }}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        Foray
                      </button> */}
                    {/* <button
                        onClick={() => {
                          handleNavToInBuild("LMS");
                          setIsOpen(false);
                        }}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        LMS
                      </button> */}
                  </div>
                </div>
              </div>
            </li>

            <li className="xl:hidden">
              <button
                className={`text-lg hover:text-primary px-3 ${
                  localStorage.getItem(STORAGE_KEYS.SELECTED_TAB) ===
                  tabs.adventures
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.adventures, adventureRoutes.home);
                }}
              >
                Adventures
              </button>
            </li>
            <li className="xl:hidden">
              <button
                className={`text-lg hover:text-primary px-3 ${
                  localStorage.getItem(STORAGE_KEYS.SELECTED_TAB) ===
                  tabs.medEval
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.medEval, medEvalRoutes.eval_center_home);
                }}
              >
                Medical Evaluation
              </button>
            </li>

            <li>
              <button
                className={`text-lg hover:text-primary px-3 ${
                  pathName === tabs.blogs
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.blogs, sharedRoutes.blogs_screen);
                }}
              >
                Blogs
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleTabClick(tabs.consultation);
                  handleNavToInBuild("Consultation");
                }}
                className={`text-lg hover:text-primary px-3 ${
                  pathName === tabs.consultation
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
              >
                Consultation
              </button>
            </li>
            <li>
              <button
                className={`text-lg hover:text-primary px-3 ${
                  pathName === tabs.contact
                    ? "text-primary font-semibold"
                    : "text-[#000000]"
                }`}
                onClick={() => {
                  handleTabClick(tabs.contact, sharedRoutes.contact_us);
                }}
              >
                Contact us
              </button>
            </li>
          </ul>
          <ul className="flex flex-col items-center gap-4 xl:gap-7 xl:hidden">
            {/* <li>
              <button
                className={`text-lg hover:text-primary px-3 ${selectedTab === tabs.proffer ? "text-primary font-semibold" : "text-[#000000]"}`}
                onClick={() => { handleTabClick(tabs.proffer, profferRoutes.home) }}
              >
                PrOffer
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleNavToInBuild("Foray");
                  handleTabClick(tabs.foray)
                }}
                className={`text-lg hover:text-primary px-3 ${selectedTab === tabs.foray ? "text-primary font-semibold" : "text-[#000000]"}`}
              >
                Foray
              </button>
            </li> */}
          </ul>
        </nav>
      </div>

      {/* Overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10 xl:hidden"
          onClick={toggleMobileMenu}
        />
      )}
    </div>
  );
};

export default PrimaryHeader;
