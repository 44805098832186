import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";
import { StaffQueryKeyString } from "../../enums/staffQueryKeyString";
import { staffSvc } from "../../services/staff.service";
import { IStaffCreate } from "../../types/IStaff";

export const useCreateStaffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ personInfo }: { personInfo: IStaffCreate }) =>
      staffSvc.createStaff(personInfo),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeyString.Staff],
      });
    },
  });
};
