import React from "react";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";

interface Props {
  label?: string;
  type: string;
  required?: boolean;
  name?: string;
  placeholder: string;
  value?: string | number;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (name: string) => void;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  min?: number;
}

const InputField: React.FC<Props> = ({
  label,
  type,
  required = false,
  name,
  placeholder,
  value,
  checked,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  min,
}) => {
  return (
    <div>
      <label htmlFor="" className="text-base text-secondaryVariant">
        {label}
        {required && <RequiredAsterisk />}
      </label>
      <input
        min={type === "number" ? (min ? min : 0) : undefined}
        type={type}
        placeholder={placeholder}
        name={name}
        className={`border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full mt-1 bg-white ${
          disabled ? "opacity-50" : ""
        }`}
        value={type !== "checkbox" ? value : undefined}
        checked={type === "checkbox" ? checked : undefined} // Handle checkbox
        onChange={onChange}
        onFocus={() => name && onFocus?.(name)}
        onBlur={() => name && onBlur?.(name)}
        disabled={disabled}
      />
    </div>
  );
};

export default InputField;
