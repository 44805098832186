export const staffMessage={
    Employee_Create:"Employee Created Successfully",
    Employee_Update:"Employee Updated Successfully",
    Employee_Delete:"Employee Deleted Successfully",
    Employee_Get:"Employee Get Successfully",
    Employee_NotFound:"Employee Not Found",
    Employee_Already:"Employee Already Exist",
    Invalid_File_Size:"File size should be less than 1MB",
    Select_Image:"Please select an image file",
    Upload_File_Error:"Error uploading file"
}