import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi2";
import { Swiper, SwiperSlide } from "swiper/react";
import SelectColumnModal from "../modals/SelectColumnModal";
import { ICategory } from "../../types/ICategories.interface";

// type Item = {
//   id: string;
//   title: string;
//   time: string;
// };

type MappedCardsProps = {
  items: ICategory[] | undefined;
  handleSelected: (id: string) => void;
  openModal: (modalType: string) => void;
};

const MappedCards: React.FC<MappedCardsProps> = ({ items, handleSelected, openModal }) => {


  // const deleteHandler = (id: string): void => {
  //   const updatedItems = items?.filter((item) => item._id !== id);
  //   setData(updatedItems);
  // };

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
        }}
        className="mySwiper"
      >
        {items?.map((item) => (
          <SwiperSlide key={item._id}>
            <div
              onClick={() => handleSelected(item._id)}
              className="w-full min-w-[277px] border-secondary rounded-lg p-6 hover:bg-primary bg-white group transition-all duration-300"
            >
              <div className="flex items-start justify-between mb-7">
                <h3 className="text-2xl text-secondary font-semibold">
                  {item.title}
                </h3>
                <div className="relative">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id={`dropdown-basic-${item._id}`}
                      className="!p-0 !border-0 bg-transparent"
                    >
                      <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-white p-2 rounded-lg shadow-lg min-w-[137px] left-[auto_!important] right-[0_!important]">
                      <Dropdown.Item
                        href="#"
                        className=" hover:text-primary py-1 px-3 flex  items-center gap-3"
                        onClick={() => { openModal("edit") }}
                      >
                        <FiEdit3 className="text-md" />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className=" hover:text-primary py-1 px-3 flex  items-center gap-3"
                      // onClick={() => deleteHandler(item._id)}
                      >
                        <HiOutlineTrash className="text-md" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <span className="text-lg text-primary font-semibold group-hover:text-white">
                {item.scheduledTime}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default MappedCards;
