import React from 'react'
import DashboardWrapper from '../../../shared/components/layout/DashboardWrapper'
import Breadcrumb from '../../../hr-setup_modules/components/Breadcrumb'
import { forayRoutes } from '../../routes/routes';
import ForayTabs from '../../components/forayTabs/ForayTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import AddNew from '../../components/addNew/AddNew';
import EssentialClassroomListTable from './components/EssentialClassroomListTable';

const EssentialClassroomList = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const breadcrumbItems = [
        { label: "Foray", link: forayRoutes.foray_screen },
        { label: "Classroom Essential", isCurrent: true },
    ];
    const handleSetupNewEssentialClassroom = () => {
        navigate(forayRoutes?.essential_classroom_setup + `?pr=${location.pathname}`);
    };

    return (
        <>
            <DashboardWrapper>
                <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
                    <Breadcrumb items={breadcrumbItems} />
                    <ForayTabs />
                    <button
                        className="btnPrimary flex items-center gap-2 max-w-[305px] mb-9"
                        onClick={handleSetupNewEssentialClassroom}
                    >
                        Setup New Essential Classroom
                    </button>
                    {/* <div className="flex items-center justify-between mb-7">
                        <h2 className="text-2xl text-secondary font-semibold">
                            Essential Classroom Information
                        </h2>
                        <div className="flex items-center text-statusColor">
                            Modify
                        </div>
                    </div> */}
                    {/* <EssentialClassroomListTable /> */}
                    {/* <AddNew
                        buttonText="Detailed Classrooms Setup"
                        route={"#" + `?pr=${location.pathname}`}
                    /> */}
                </div>
            </DashboardWrapper>
        </>
    )
}

export default EssentialClassroomList