import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";

export const useCompleteChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      checklistId,
      initials,
    }: {
      checklistId: string;
      initials: string;
    }) => checklistSvc.completeChecklistItem(checklistId, initials),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ChecklistQueryKeyString.Checklist],
      });
    },
  });
};
