import { FC, useState } from "react";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { FiEdit } from "react-icons/fi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IPartTimeSessions, NewClassroom } from "../../types/IClassroomSetup.interface";
import InputField from "../inputsFields/InputField";
import ReactSelect from "../reactSelect/ReactSelect";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";

interface Props {
  essentialInfo: NewClassroom[];
  partTimeSessions: IPartTimeSessions[]
  editNonSequentialClasses: boolean;
  setEditNonSequentialClasses: (check: boolean) => void
  handleInputChange: (
    name: string,
    value: string | number,
    index: number
  ) => void;
  handleAddPartTimeSessions: (checked: boolean, index: number, isSequential: boolean) => void
  handleAddClassroom: (index: number) => void;
  handleRemoveClassroom: (index: number) => void;
  handleSave: () => void;
  errors: string[];
  classroomOptions: Options[];
  handleCancel: () => void;
}
interface Options {
  value: string;
  label: string;
}

const NonAgeEssential: FC<Props> = ({
  essentialInfo,
  partTimeSessions,
  editNonSequentialClasses,
  setEditNonSequentialClasses,
  handleInputChange,
  handleAddPartTimeSessions,
  handleAddClassroom,
  handleRemoveClassroom,
  handleSave,
  errors,
  classroomOptions,
  handleCancel,
}) => {
  // const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    setEditNonSequentialClasses(!editNonSequentialClasses);
  };
  const tooltips = {
    baProgram: {
      id: "baProgram-tooltip",
      content: `<p>This classroom is in BA program. To assign part-time sessions to this classroom, first remove it from BA program through classroom detailed setup.</p>`,
    },
  }

  return (
    <>
      <h3 className="text-secondary font-semibold text-xl mb-3">Section 2</h3>
      <div className="bg-white p-6 rounded-xl space-y-3 mb-3">
        <div className="flex items-center justify-between gap-3">
          <h2 className="text-2xl font-medium mb-3 flex items-start gap-2">
            Age-Non-Sequential Classrooms
            {/* <span>
              <IoAlertCircleOutline />
            </span> */}
          </h2>
          {!editNonSequentialClasses && (
            <button
              onClick={handleEdit}
              className="text-statusColor inline-flex items-center gap-2 text-lg"
            >
              Modify <FiEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-12 gap-4 bg-[#F2F2F2] p-2 rounded-md">
          <div className="col-span-1">
            <p className="text-base text-secondary font-semibold">
              Classroom <br />{" "}
              <span className="text-base font-semibold "> Name *</span>
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-base text-secondary font-semibold">
              Classroom has  <br />{" "}
              <span className="text-base font-semibold "> Part-time Sessions?</span>
            </p>
          </div>

          <div className="col-span-8">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Minimum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Maximum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-base text-secondary font-semibold ">
              Student <br /> Transitions To
            </p>
          </div>
        </div>
        {essentialInfo?.map((classroom: any, index) => (
          <>
            <div className="grid grid-cols-12 mb-4 items-end h-full">
              <div className="col-span-1 bg-[#F2F2F2] p-2 rounded-tl-[4px] rounded-bl-[4px]">
                <InputField
                  disabled={!editNonSequentialClasses}
                  label="Name"
                  name="classroomName"
                  type="text"
                  placeholder=""
                  required
                  value={classroom.classroomName}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value, index)
                  }
                />{" "}
              </div>
              {classroom?.BAProgram ?
                <>
                  < div className="flex gap-2 col-span-1 bg-[#F2F2F2] p-2 h-full pt-12 text-sm">
                    BA Program <span data-tooltip-id={tooltips.baProgram.id}><IoAlertCircleOutline className="w-4 h-4 text-dangerAlert" /></span>
                  </div>
                  <TooltipComponent
                    id={tooltips.baProgram.id}
                    content={tooltips.baProgram.content}
                    isWhite={true}
                  />
                </> :
                <div className="flex gap-2 col-span-1 bg-[#F2F2F2] p-2 h-full pt-12">
                  <InputField
                    disabled={!editNonSequentialClasses || partTimeSessions?.length === 0}
                    name="hasPartTimeSession"
                    type="checkbox"
                    placeholder=""
                    checked={classroom?.partTimeSlots?.length > 0}
                    onChange={(e) =>
                      handleAddPartTimeSessions(e.target.checked, index, true)
                    }

                  />
                  <label htmlFor="" className="text-secondaryVariant text-base">
                    Yes
                  </label>
                </div>
              }
              <div className="col-span-8 bg-[#F2F2F2] p-2 h-full flex flex-col justify-end">
                <div className="grid grid-cols-2 gap-x-4">
                  <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold items-center">
                    <div>
                      <InputField
                        disabled={!editNonSequentialClasses}
                        name="minimumAge.days"
                        type="number"
                        placeholder=""
                        value={classroom.ageRange.minimumAge.days as number}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                      />{" "}
                    </div>
                    <div>
                      <InputField
                        disabled={!editNonSequentialClasses}
                        name="minimumAge.months"
                        type="number"
                        placeholder=""
                        value={classroom.ageRange.minimumAge.months as number}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                      />{" "}
                    </div>
                    <div>
                      <InputField
                        disabled={!editNonSequentialClasses}
                        name="minimumAge.years"
                        type="number"
                        placeholder=""
                        value={classroom.ageRange.minimumAge.years as number}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                      />{" "}
                    </div>
                    <div className="text-base text-primary flex items-start flex-col gap-2">
                      <span className="font-semibold">
                        {classroom.ageRange.minimumAge.ageInDays || 0}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold items-center">
                      <div>
                        <InputField
                          disabled={!editNonSequentialClasses}
                          name="maxAge.days"
                          type="number"
                          placeholder=""
                          value={classroom.ageRange.maximumAge.days as number}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }
                        />{" "}
                      </div>
                      <div>
                        <InputField
                          disabled={!editNonSequentialClasses}
                          name="maxAge.months"
                          type="number"
                          placeholder=""
                          value={classroom.ageRange.maximumAge.months as number}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }
                        />{" "}
                      </div>
                      <div>
                        <InputField
                          disabled={!editNonSequentialClasses}
                          name="maxAge.years"
                          type="number"
                          placeholder=""
                          value={classroom.ageRange.maximumAge.years as number}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }
                        />{" "}
                      </div>
                      <div className="text-base text-primary flex items-start flex-col gap-2">
                        <span className="font-semibold">
                          {classroom.ageRange.maximumAge.ageInDays || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 h-full flex items-center">
                <div className="flex gap-4 h-full flex-1 items-center ">
                  <div className="flex-1 flex flex-col bg-[#F2F2F2] p-2 justify-center rounded-tr-[4px] rounded-br-[4px] h-full">
                    <ReactSelect
                      isDisabled={!editNonSequentialClasses}
                      label={""}
                      placeholder={"Select slot"}
                      options={classroomOptions}
                      value={classroomOptions.find(
                        (option) =>
                          option.value ===
                          classroom.transition?.TransitionClassroom
                      )}
                      onChange={(selectedOption: any) =>
                        handleInputChange(
                          "TransitionClassroom",
                          selectedOption,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex-[0_0_71px]">
                  {editNonSequentialClasses && (
                    <div>
                      <div className="flex items-center justify-center gap-3 pt-5">
                        {index !== 0 && (
                          <button
                            className="text-base text-dangerAlert flex items-center flex-col"
                            onClick={() => handleRemoveClassroom(index)}
                          >
                            <CiCircleMinus className="w-6 h-6" />
                            Delete
                          </button>
                        )}
                        <button
                          className="text-base text-statusColor flex items-center flex-col"
                          onClick={() => handleAddClassroom(index)}
                        >
                          <IoIosAddCircleOutline className="w-6 h-6" />
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
        {editNonSequentialClasses && (
          <div className="mb-3">
            {errors &&
              errors?.map((error) => {
                return (
                  <div>
                    <p className="text-dangerAlert">{error}</p>
                  </div>
                );
              })}
            {errors && <br />}
            <p className="text-secondary text-sm mb-4">
              *Save only after you have added ALL classrooms in this section
            </p>
            <button
              onClick={() => {
                handleSave();
                // setIsEdit(false);
              }}
              className={`bg-secondary text-white rounded-lg h-[58px] px-6 min-w-[125px] ${errors?.length > 0 ? "opacity-50" : ""}`}
              disabled={errors?.length > 0}
            >
              Save
            </button>
            <button
              onClick={() => {
                handleCancel();
                setEditNonSequentialClasses(false);
              }}
              className=" text-black rounded-lg h-[58px] ml-2 px-6 min-w-[125px] "
            >
              Cancel
            </button>
          </div>
        )}
        {/* {essentialInfo.length < 0 && (
          <button className="text-statusColor inline-flex items-center justify-center  rounded-lg border border-[#f2f2f2] h-[58px] px-6 gap-3">
            <CiCirclePlus size={24} />
            Add Age-Sequential Classroom
          </button>
        )} */}
      </div >
    </>
  );
};

export default NonAgeEssential;
