import React from 'react'
import { BsFileEarmarkCheck } from 'react-icons/bs'
import { FaRegEdit } from 'react-icons/fa'

const UploadDocuments = ({title,buttonLabel,onEdit,uploadDocuments}:any) => {
  return (
    <div className='bg-white rounded-xl gap-6'>
      <div className="flex items-center justify-between py-3 px-6">
        <h2 className="text-xl font-medium">{title}</h2>
        <button onClick={onEdit} className="inline-flex items-center gap-3 text-info">
          {buttonLabel} <FaRegEdit size={20} />
        </button>
      </div>
      <div className="flex flex-wrap">
        {uploadDocuments.map((item:any, index:any) => (
          <div
            key={index}
            className="w-1/2 flex items-center flex-col gap-1 text-lg font-normal py-4 px-6 text-secondary border-t border-black/10"
          >
            <div className="text-secondary/70 text-left w-full">
              {item.label}
            </div>
            <div className='flex items-center gap-3 w-full py-2 px-3 bg-neutral-200 border-secondaryVariant2 rounded-md text-base'>
             <BsFileEarmarkCheck />
              <span className='flex-1'>{item.value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UploadDocuments