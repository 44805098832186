import React from "react";

const SetupInstruction = () => {
  return (
    <>
      <div className="mb-6">
        <h2 className="text-2xl text-secondary font-semibold mb-3">
          Essential Classroom Setup
        </h2>
        <p className="text-base text-secondary">
          The order in which you setup your classrooms is{" "}
          <strong>VERY IMPORTANT.</strong>
        </p>
        <ol className="list-decimal text-secondary text-base ps-5 max-w-[710px]">
          <li>
            You <strong>MUST</strong> start with the youngest classroom.
          </li>
          <li>
            You <strong>MUST</strong> create the next classroom in sequence.{" "}
          </li>
          <li>There can not be overlapped between classroom age groups.</li>
          <li>There can not be gaps between classroom age groups.</li>
          <li>
            You may enter classroom age group in months, years or days. The
            application will automatically use days for increased precision.
          </li>
          <li>
            You <strong>MUST</strong> complete basic information for all
            classroom <strong>BEFORE</strong> moving to the next step.
          </li>
          <li>
            <strong> EXAMPLE:</strong> Your center has two classrooms:{" "}
            <strong>0-6 months</strong> and <strong>6 months - 1 year.</strong>
            <div className="text-base font-bold text-secondary">
              <span className="text-successAlert block">
                {" "}
                Correct: Classroom 1 - 0-180 days. Classroom 2 - 181 Days to 365
                Days
              </span>
              <p>
                {" "}
                <span className="text-dangerAlert">Wrong:</span> Classroom 1 -
                0-180 days. Classroom 2 -
                <span className="text-dangerAlert">178</span>
                Days to 365 Days{" "}
                <span className="text-dangerAlert">(Overlap)</span>{" "}
                <p>
                  <span className="text-dangerAlert">Wrong:</span> Classroom 1 - 0-180 days. Classroom 2 -{" "}
                  <span className="text-dangerAlert">184</span> Days to 365 Days
                  <span className="text-dangerAlert"> (Gap)</span>
                </p>
              </p>
            </div>
          </li>
          <li>
            Please make sure you add <strong>ALL</strong> your classrooms below.
          </li>
        </ol>
      </div>
    </>
  );
};

export default SetupInstruction;
