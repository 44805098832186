export const educationLevels = [
  { value: "In High School", label: "In High School" },
  { value: "High School Diploma", label: "High School Diploma" },
  {
    value: "Associate's degree in childcare",
    label: "Associate's degree in childcare",
  },
  { value: "Associate's degree", label: "Associate's degree" },
  { value: "Bachelors", label: "Bachelors" },
  { value: "Masters", label: "Masters" },
  { value: "Doctorate", label: "Doctorate" },
];

export const certifications = [
  { value: "infant_lead", label: "Infant Lead" },
  { value: "infant_assistant", label: "Infant Assistant" },
  { value: "toddler_lead", label: "Toddler Lead" },
  { value: "toddler_assistant", label: "Toddler Assistant" },
  { value: "infant_toddler_lead", label: "Infant-Toddler Lead" },
  { value: "infant_toddler_assistant", label: "Infant-Toddler Assistant" },
  { value: "preschool_lead", label: "Preschool Lead" },
  { value: "preschool_assistant", label: "Preschool Assistant" },
  { value: "school_age_lead", label: "School-Age Lead" },
  { value: "school_age_assistant", label: "School-Age Assistant" },
  { value: "director", label: "Director" },
  { value: "kindergarten_certified", label: "Kindergarten Certified" },
  { value: "add_other_certification", label: "Add Other Certification" },
];

export const ceuUnits = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
];

export const employmentTypes = [
  { value: "full_time", label: "Full Time" },
  { value: "part_time", label: "Part Time" },
  { value: "as_needed_prn", label: "As Needed / PRN" },
  { value: "other", label: "Other" },
];

export const jobTitles = [
  { value: "aide", label: "Aide" },
  { value: "assistant_teacher", label: "Assistant Teacher" },
  { value: "lead_teacher", label: "Lead Teacher" },
  { value: "assistant_director", label: "Assistant Director" },
  { value: "director", label: "Director" },
  { value: "regional_director", label: "Regional Director" },
  { value: "other", label: "Other (customizable to add other descriptions)" },
];

export const salaryTypes = [
  { value: "per_hour", label: "Per hour" },
  { value: "per_month", label: "Per month" },
  { value: "per_year", label: "Per year" },
];
