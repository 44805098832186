import React from "react";
import { Link } from "react-router-dom";
import { LuHouse } from "react-icons/lu";
import { GoChevronRight } from "react-icons/go";

export interface BreadcrumbItem {
  label: string;
  link?: string;
  state?: any;
  isCurrent?: boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className="flex items-center gap-2 mb-9">
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          {item.link && !item.isCurrent ? (
            <Link
              to={item.link}
              state={item.state}
              className="text-secondaryVariant2 text-sm font-normal group hover:text-primary inline-flex items-center gap-2"
            >
              {index === 0 && (
                <LuHouse className="w-5 h-5 text-secondaryVariant group-hover:text-primary" />
              )}
              {item.label}
            </Link>
          ) : (
            <span
              className={`text-sm ${item.isCurrent
                ? "text-primary font-medium"
                : "text-secondaryVariant2 font-normal"
                }`}
            >
              {item.label}
            </span>
          )}
          {index < items.length - 1 && (
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
