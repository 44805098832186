import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import Breadcrumb, { BreadcrumbItem } from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../shared/utils/toast";
import EssentialInformation from "../../components/Essential/EssentialInformation";
import EssentialTransition from "../../components/essentialTransition/EssentialTransition";
import NonAgeEssential from "../../components/nonAgeEssential/NonAgeEssential";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import {
  IClassroomSetup,
  IEssentialClassroomSetup,
  IPartTimeSessions,
  NewClassroom,
} from "../../types/IClassroomSetup.interface";
import { calculateYearsMonthsDays } from "../../utils/functions/calculateYMD";
import {
  convertToDays,
  validateClassrooms,
} from "../../utils/functions/validateClassrooms";
import SetupInstruction from "./SetupInstruction";
import { toastMessage } from "../../constants/toastMessages";
import ReviewEssentialClassroomsModal from "../../components/Essential/ReviewEssentialClassroomsModal";
import PartTimeSession from "./PartTimeSession";
import { initPartTimeSessionEssentials } from "../../constants/initialState";
import { SessionType, Slot } from "../../enums/Foray.enums";
import ConfirmationModal from "../../components/modals/BaProgramConfirmationModal";
import { studentSvc } from "../../services/student.service";
import { useLocation } from "react-router-dom";

const initialMinMaxAge = {
  years: 0,
  months: 0,
  days: 0,
  ageInDays: 0,
  countAllowedOutsideRange: 0,
};

const initialNewClassroom: any = {
  classroomName: "",
  ageRange: {
    minimumAge: { ...initialMinMaxAge },
    maximumAge: { ...initialMinMaxAge },
  },
  partTimeSlots: [],
};

interface Options {
  value: string;
  label: string;
}

const EssentialClassroomSetup = () => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [partTimeSessions, setPartTimeSessions] = useState<IPartTimeSessions[]>([])
  const [classroomData, setClassroomData] = useState<IClassroomSetup[]>([]);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [savingClassrooms, setSavingClassrooms] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [havePartTimeSessions, setHavePartTimeSessions] = useState(false)
  const [editSequentialClasses, setEditSequentialClasses] = useState(false)
  const [editNonSequentialClasses, setEditNonSequentialClasses] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [classrooms, setClassrooms] = useState<NewClassroom[]>([initialNewClassroom]);
  const [nonAgeSequentialClassrooms, setNonAgeSequentialClassrooms] = useState<NewClassroom[]>([initialNewClassroom]);
  const [classroomOptions, setClassroomOptions] = useState<Options[]>([]);
  const prevRoute = new URLSearchParams(window.location.search).get("pr");

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    prevRoute === forayRoutes.center_view ? { label: "Center View", link: forayRoutes.center_view } : { label: "Essential Classroom", link: forayRoutes.essential_classroom_list },
    { label: "Essential Classrooms Setup", isCurrent: true },
  ] as BreadcrumbItem[];

  const fetchClassrooms = async () => {
    const resp = await classroomSvc.fetchClassrooms();
    setClassroomData(resp?.data.existingClassroomSetups);
    const formattedClassrooms =
      resp?.data?.existingClassroomSetups?.map(
        (classroom: IClassroomSetup) => ({
          value: classroom._id,
          label: classroom.classroomName,
        })
      ) || [];

    const partTimeSlotsClassroom = resp?.data?.existingClassroomSetups?.find((classroom: IClassroomSetup) => (classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram))
    if (partTimeSlotsClassroom) {
      setPartTimeSessions(() => {
        const updatedPartTimeSessions = partTimeSlotsClassroom?.partTimeSlots?.map((session: IPartTimeSessions) => ({
          name: session.name,
          startTime: session.startTime,
          endTime: session.endTime,
          transitionTo: session?.transition,
          uuid: session.uuid,
          _id: session._id,
        }))
        return updatedPartTimeSessions;
      })
      setHavePartTimeSessions(true)
    }

    const sequentialClassroom = resp?.data?.existingClassroomSetups.filter(
      (classroom: any) => classroom.ageSequential === true
    );
    if (sequentialClassroom.length > 0) {
      setClassrooms(sequentialClassroom);
    } else {
      setClassrooms([initialNewClassroom]);
    }

    const nonSequentialClassroom = resp?.data?.existingClassroomSetups.filter(
      (classroom: any) => classroom.ageSequential === false
    );

    setClassroomOptions(formattedClassrooms);

    if (nonSequentialClassroom.length > 0) {
      setNonAgeSequentialClassrooms(nonSequentialClassroom);
    } else {
      setNonAgeSequentialClassrooms([initialNewClassroom]);
    }
  };

  // Validate classrooms whenever the classrooms change
  useEffect(() => {
    if (!classrooms?.length || !classrooms[0]?.classroomName) return
    const { errors } = validateClassrooms(classrooms);
    setValidationErrors(errors);
  }, [classrooms]);
  useEffect(() => {
    if (!nonAgeSequentialClassrooms?.length || !nonAgeSequentialClassrooms[0]?.classroomName) return;
    const allClasses = [...classrooms, ...nonAgeSequentialClassrooms];
    const { errors } = validateClassrooms(allClasses, true);
    setValidationErrors(errors);

  }, [nonAgeSequentialClassrooms]);

  const handleInputChange = (
    name: string,
    value: string | number,
    index: number
  ) => {
    setClassrooms((prev) => {
      const updatedClassrooms = [...prev];
      const classroom = { ...updatedClassrooms[index] };

      // Handle classroom name
      if (name === "classroomName") {
        classroom.classroomName = value as string;
      }
      // Handle minimum age fields
      else if (name.startsWith("minimumAge")) {
        const field = name.split(".").pop(); // gets 'days', 'months', or 'years'
        if (field) {
          classroom.ageRange.minimumAge = {
            ...classroom.ageRange.minimumAge,
            [field]: Number(value),
          };

          // Recalculate ageInDays for minimum age
          const ageInDays = convertToDays(
            classroom.ageRange.minimumAge.days,
            classroom.ageRange.minimumAge.months,
            classroom.ageRange.minimumAge.years
          );
          classroom.ageRange.minimumAge.ageInDays = ageInDays;
          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.minimumAge.days = days;
          classroom.ageRange.minimumAge.months = months;
          classroom.ageRange.minimumAge.years = years;
        }
      }
      // Handle maximum age fields
      else if (name.startsWith("maxAge")) {
        const field = name.split(".").pop(); // gets 'days', 'months', or 'years'
        if (field) {
          classroom.ageRange.maximumAge = {
            ...classroom.ageRange.maximumAge,
            [field]: Number(value),
          };

          // Recalculate ageInDays for maximum age
          // classroom.ageRange.maximumAge.ageInDays = convertToDays(
          //   classroom.ageRange.maximumAge.days,
          //   classroom.ageRange.maximumAge.months,
          //   classroom.ageRange.maximumAge.years
          // );
          const ageInDays = convertToDays(
            classroom.ageRange.maximumAge.days,
            classroom.ageRange.maximumAge.months,
            classroom.ageRange.maximumAge.years
          );
          classroom.ageRange.maximumAge.ageInDays = ageInDays;
          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          // classroom.ageRange.maximumAge.ageInDays = days;
          classroom.ageRange.maximumAge.days = days;
          classroom.ageRange.maximumAge.months = months;
          classroom.ageRange.maximumAge.years = years;
          console.log("calculateYearsMonthsDays", days, months, years);
        }
      }

      updatedClassrooms[index] = classroom;
      return updatedClassrooms;
    });
  };

  const handleAddPartTimeSessions = (
    checked: boolean,
    index: number,
    isSequential: boolean
  ) => {
    const updateClassrooms = (tempClassrooms: typeof classrooms) => {
      return tempClassrooms?.map((classroom, idx) => {
        if (idx !== index) return classroom;

        if (checked) {
          console.log("check")
          return {
            ...classroom,
            partTimeSlots: partTimeSessions?.map((session, i) => ({
              ...session,
              _id: classroom?.partTimeSlots?.[i]?._id || session._id,
              uuid: classroom?.partTimeSlots?.[i]?.uuid || session.uuid,
              transitionTo: classroom?.partTimeSlots?.[i]?.transition || session.transition
            })),
            settingType: SessionType.FullAndPartTime,
          };
        } else {
          return {
            ...classroom,
            partTimeSlots: [],
            settingType: SessionType.FullTimeOnly,
          };
        }
      });
    };

    if (isSequential) {
      setClassrooms((prev) => {
        if (!prev) {
          console.error("Classrooms data is undefined.");
          return [];
        }
        return updateClassrooms(prev);
      });
    } else {
      setNonAgeSequentialClassrooms((prev) => {
        if (!prev) {
          console.error("Non-age sequential classrooms data is undefined.");
          return [];
        }
        return updateClassrooms(prev);
      });
    }
  };

  const handleAddClassroom = (index: number) => {
    setClassrooms((prev: any) => {
      // Ensure index is within bounds
      if (index < 0 || index >= prev?.length) {
        showErrorToast("Invalid index provided for adding a new classroom.");
        return prev;
      }

      // Check if the specified classroom has a valid maximum age
      const currentClassroom = prev[index];
      const hasValidMaxAge = currentClassroom.ageRange.maximumAge.ageInDays > 0;

      if (!hasValidMaxAge) {
        showErrorToast(
          "Please set the maximum age for the specified classroom first."
        );
        return prev;
      }

      const currentMaxAge = currentClassroom.ageRange.maximumAge;

      // Calculate total days of the specified classroom's maximum age
      const currentTotalDays = convertToDays(
        currentMaxAge.days,
        currentMaxAge.months,
        currentMaxAge.years
      );

      // Convert (currentTotalDays + 1) to years, months, and days
      const newMinimumAge = calculateYearsMonthsDays(currentTotalDays + 1);

      // Create new classroom with calculated minimum age
      const newClassroom = {
        classroomName: "",
        ageRange: {
          minimumAge: {
            ...newMinimumAge,
            ageInDays: currentTotalDays + 1,
            countAllowedOutsideRange: 0,
          },
          maximumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
        },
      };

      // Insert the new classroom at the specified index + 1
      const updatedClassrooms = [...prev];
      updatedClassrooms?.splice(index + 1, 0, newClassroom);

      return updatedClassrooms;
    });
  };

  const handleRemoveClassroom = (index: number) => {
    setClassrooms((prev) => {
      // Check if index is valid
      if (index < 0 || index >= prev.length) {
        console.error("Invalid index for classroom removal");
        return prev;
      }

      // Prevent removing the last classroom
      if (prev.length <= 1) {
        showErrorToast("At least one classroom is required");
        return prev;
      }

      // Remove the classroom at the specified index
      return prev.filter((_, i) => i !== index);
    });
  };

  const handleOpenSaveModal = (classroom: string) => {
    setSavingClassrooms(classroom);
    setIsOpenReviewModal(true);
  };
  const handleCloseSaveModal = () => {
    setSavingClassrooms("");
    setIsOpenReviewModal(false);
  };

  const handleSave = async () => {
    if (!classrooms?.length || !classrooms[0]?.classroomName) return
    const { isValid, errors } = validateClassrooms(classrooms);
    if (!isValid) {
      setValidationErrors(errors);
      showErrorToast("please correct the errors before saving");
      // errors.map((error) => showErrorToast(error));

      return;
    }

    const checkedClassrooms = classrooms?.map((classroom) => ({
      ...classroom,
      partTimeSlots: classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram ? partTimeSessions : []
    }))

    const existingClassrooms = checkedClassrooms.filter(
      (classroom: any) => classroom._id
    );
    const newlyAddedClassrooms = checkedClassrooms.filter(
      (classroom: any) => classroom._id === undefined
    );

    const payload: IEssentialClassroomSetup = {
      newClassrooms: newlyAddedClassrooms,
      editedClassrooms: existingClassrooms,
    };

    console.log("Classrooms are valid. Saving...", payload);
    const resp = await classroomSvc.createEssentialClassrooms(payload, true);

    if (resp?.ok) {
      fetchClassrooms();
      showSuccessToast(toastMessage.Updated_Successfully);
      handleCloseSaveModal()
      setEditSequentialClasses(false)
    } else if (
      resp?.error.statusCode === 400 &&
      resp?.error?.message?.includes(
        "Cannot remove part-time slots as there are students assigned to the following"
      )
    ) {
      showErrorToast(resp?.error?.message);
      setIsOpenReviewModal(false)
      setIsConfirmationModalOpen(true);
    } else {
      showErrorToast(resp?.error.message);
    }
  };

  const handleInputChangeNonSeq = (
    name: string,
    value: string | number | SingleValue<Options>,
    index: number
  ) => {
    setNonAgeSequentialClassrooms((prev) => {
      const updatedClassrooms = [...prev];
      const classroom = { ...updatedClassrooms[index] };

      if (name === "classroomName") {
        classroom.classroomName = value as string;
      } else if (name === "TransitionClassroom") {
        console.log("value", value);
        // Handle ReactSelect transition classroom selection
        if (value) {
          const selectedTransition = value as { value: string; label: string };
          classroom.transition = {
            ...classroom.transition,
            TransitionClassroom: selectedTransition.value,
          };
        } else {
          classroom.transition = {
            ...classroom.transition,
            TransitionClassroom: "",
          };
        }
      } else if (name.startsWith("minimumAge")) {
        const field = name.split(".").pop();
        if (field) {
          classroom.ageRange.minimumAge = {
            ...classroom.ageRange.minimumAge,
            [field]: Number(value),
          };

          const ageInDays = convertToDays(
            classroom.ageRange.minimumAge.days,
            classroom.ageRange.minimumAge.months,
            classroom.ageRange.minimumAge.years
          );
          classroom.ageRange.minimumAge.ageInDays = ageInDays;

          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.minimumAge.days = days;
          classroom.ageRange.minimumAge.months = months;
          classroom.ageRange.minimumAge.years = years;
        }
      } else if (name.startsWith("maxAge")) {
        const field = name.split(".").pop();
        if (field) {
          classroom.ageRange.maximumAge = {
            ...classroom.ageRange.maximumAge,
            [field]: Number(value),
          };

          const ageInDays = convertToDays(
            classroom.ageRange.maximumAge.days,
            classroom.ageRange.maximumAge.months,
            classroom.ageRange.maximumAge.years
          );
          classroom.ageRange.maximumAge.ageInDays = ageInDays;

          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.maximumAge.days = days;
          classroom.ageRange.maximumAge.months = months;
          classroom.ageRange.maximumAge.years = years;

          // Calculate transition age (+1 day from maximum age)
          const transitionAgeInDays = ageInDays + 1;
          const transitionAge = calculateYearsMonthsDays(transitionAgeInDays);

          classroom.transition = {
            ...classroom.transition,
            years: transitionAge.years,
            months: transitionAge.months,
            days: transitionAge.days,
            ageInDays: transitionAgeInDays,
          };
        }
      }

      updatedClassrooms[index] = classroom;
      return updatedClassrooms;
    });
  };

  const handleSequentialSave = () => {
    handleOpenSaveModal("sequential");
  };
  const handleNonSequentialSave = () => {
    handleOpenSaveModal("non-sequential");
  };

  const handleNonSeqClasses = async () => {
    if (!nonAgeSequentialClassrooms?.length || !nonAgeSequentialClassrooms[0]?.classroomName) return
    const { isValid, errors } = validateClassrooms(nonAgeSequentialClassrooms);

    if (!isValid) {
      setValidationErrors(errors);
      showErrorToast("please correct the errors before saving");
      // errors.map((error) => showErrorToast(error));

      return;
    }
    const checkedNonSeqClassrooms = nonAgeSequentialClassrooms?.map((classroom) => ({
      ...classroom,
      partTimeSlots: classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram ? partTimeSessions : []
    }))

    const existingClassrooms = checkedNonSeqClassrooms.filter(
      (classroom: any) => classroom._id
    );
    const newlyAddedClassrooms = checkedNonSeqClassrooms.filter(
      (classroom: any) => classroom._id === undefined
    );
    const payload = {
      newClassrooms: newlyAddedClassrooms,
      editedClassrooms: existingClassrooms,
    };
    const resp = await classroomSvc.createEssentialClassrooms(payload, false);
    if (resp?.ok) {
      showSuccessToast(toastMessage.Updated_Successfully);
    } else if (
      resp?.error.statusCode === 400 &&
      resp?.error?.message?.includes(
        "Cannot remove part-time slots as there are students assigned to the following"
      )
    ) {
      showErrorToast(resp?.error?.message);
      setIsOpenReviewModal(false)
      setIsConfirmationModalOpen(true);
    } else {
      fetchClassrooms();
      showErrorToast(resp?.error.message);
    }
  };

  const handleAddNonSeqClass = (index: number) => {
    setNonAgeSequentialClassrooms((prev) => {
      // Ensure index is within bounds
      if (index < 0 || index >= prev?.length) {
        showErrorToast("Invalid index provided for adding a new classroom.");
        return prev;
      }

      // Create new classroom with empty age range
      const newClassroom: any = {
        classroomName: "",
        ageRange: {
          minimumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
          maximumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
        },
      };

      // Insert the new classroom at the specified index + 1
      const updatedClassrooms = [...prev];
      updatedClassrooms?.splice(index + 1, 0, newClassroom);

      return updatedClassrooms;
    });
  };

  const handleRemoveNonSeqClass = (index: number) => {
    setNonAgeSequentialClassrooms((prev) => {
      if (index < 0 || index >= prev.length) {
        console.error("Invalid index for classroom removal");
        return prev;
      }

      // Prevent removing the last classroom
      if (prev.length <= 1) {
        showErrorToast("At least one classroom is required");
        return prev;
      }

      // Remove the classroom at the specified index
      return prev.filter((_, i) => i !== index);
    });
  };

  const handleCancel = () => {
    fetchClassrooms();
  };

  const handleCancelPartTimeSession = () => {
    ///need to change it.... may re fetch the api
    const partTimeSlotsClassroom = classroomData?.find((item) => item.partTimeSlots?.length > 0 && !item.BAProgram)

    if (partTimeSlotsClassroom) {
      setPartTimeSessions(() => {
        const updatedPartTimeSessions = partTimeSlotsClassroom?.partTimeSlots?.map((session) => ({
          name: session.name as string,
          startTime: session.startTime as string,
          endTime: session.endTime as string,
          transition: session?.transition as string,
          uuid: session.uuid,
          _id: session._id,
        }))
        return updatedPartTimeSessions;
      })
    } else {
      setPartTimeSessions([]);
      setHavePartTimeSessions(false)
    }
  }


  useEffect(() => {
    fetchClassrooms();
  }, []);

  useEffect(() => {
    //this is to un-assign part time slots of a classroom
    if (havePartTimeSessions) return;

    const updateAllClassrooms = (tempClassrooms: typeof classrooms) => {
      return tempClassrooms?.map((classroom, index) => {
        // if (classroomData[index]?.BAProgram) {
        //   showWarningToast(
        //     "Can't assign part-time sessions as this classroom has a BA program. If you want to assign these sessions, change the classroom settings from detailed classroom setup."
        //   );
        //   return classroom;
        // }

        return {
          ...classroom,
          partTimeSlots: [], // Clear partTimeSlots for all classrooms
          settingType: SessionType.FullTimeOnly, // Update settingType
        };
      });
    };

    setClassrooms((prev) => updateAllClassrooms(prev));
    setNonAgeSequentialClassrooms((prev) => updateAllClassrooms(prev));
  }, [havePartTimeSessions]);

  const handleProceed = async () => {
    const tempClassrooms = savingClassrooms === "sequential" ? classrooms : nonAgeSequentialClassrooms
    if (!tempClassrooms?.length || !tempClassrooms[0]?.classroomName) return;
    // Find classrooms where partTimeSlots has changed to an empty array
    const modifiedClassrooms = tempClassrooms?.filter((tempClassroom) => {
      const originalClassroom = classroomData?.find((item) => item._id === tempClassroom._id);

      // Compare partTimeSlots
      return originalClassroom &&
        originalClassroom.partTimeSlots?.length > 0 &&
        tempClassroom.partTimeSlots?.length === 0;
    });

    if (modifiedClassrooms.length > 0) {
      // Handle the modified classrooms with empty partTimeSlots
      for (const classroom of modifiedClassrooms) {
        if (classroom?._id)
          await studentSvc.moveStudentsToFullTime(classroom?._id);
        else {
          showErrorToast(`Something went wrong while moving students to full time in ${classroom?.classroomName}`);
          return;
        }
      }
    }
    if (savingClassrooms === "sequential") handleSave()
    else handleNonSeqClasses()
  };
  console.log("check both classes: ", classrooms, nonAgeSequentialClassrooms)
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <SetupInstruction />
          <PartTimeSession partTimeSessions={partTimeSessions} setPartTimeSessions={setPartTimeSessions} havePartTimeSessions={havePartTimeSessions} setHavePartTimeSessions={setHavePartTimeSessions} handleCancelPartTimeSession={handleCancelPartTimeSession} handleSave={handleSave} handleNonSeqClasses={handleNonSeqClasses} />
          <EssentialInformation
            essentialInfo={classrooms}
            partTimeSessions={partTimeSessions}
            editSequentialClasses={editSequentialClasses}
            setEditSequentialClasses={setEditSequentialClasses}
            handleInputChange={handleInputChange}
            handleAddPartTimeSessions={handleAddPartTimeSessions}
            handleAddClassroom={handleAddClassroom}
            handleRemoveClassroom={handleRemoveClassroom}
            handleSave={handleSequentialSave}
            errors={validationErrors}
            classroomOptions={classroomOptions}
            handleCancel={handleCancel}
          />
          <NonAgeEssential
            essentialInfo={nonAgeSequentialClassrooms ?? []}
            partTimeSessions={partTimeSessions}
            editNonSequentialClasses={editNonSequentialClasses}
            setEditNonSequentialClasses={setEditNonSequentialClasses}
            handleInputChange={handleInputChangeNonSeq}
            handleAddPartTimeSessions={handleAddPartTimeSessions}
            handleAddClassroom={handleAddNonSeqClass}
            handleRemoveClassroom={handleRemoveNonSeqClass}
            handleSave={handleNonSequentialSave}
            classroomOptions={classroomOptions}
            errors={validationErrors}
            handleCancel={handleCancel}
          />
          <EssentialTransition
            classroomOptions={classroomOptions}
            classrooms={classroomData}
          />
          <ReviewEssentialClassroomsModal
            classrooms={
              savingClassrooms === "sequential"
                ? classrooms
                : nonAgeSequentialClassrooms
            }
            classroomOptions={classroomOptions}
            isOpen={isOpenReviewModal}
            onClose={handleCloseSaveModal}
            onSave={savingClassrooms === "sequential" ? handleSave : handleNonSeqClasses}
          />
          {/* <button
            className={`h-[56px] w-full max-w-[298px] mt-6 ${isDisabled ? "bg-[#DADADA] text-white" : "btnPrimary"
              }`}
            disabled={isDisabled}
          >
            Save Essential Classroom
          </button> */}
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onProceed={handleProceed}
            message="This action cannot be undone. All selected students will be withdrawn from the program."
            actionText="You have unchecked part-time program but there are still students in the sessions. Do you want to transfer all students to full time?"
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EssentialClassroomSetup;
