import React, { useState, useEffect } from "react";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleMinus } from "react-icons/ci";
import {
  certifications,
  ceuUnits,
  educationLevels,
} from "../../constants/dropdowns";
import {
  Certificate,
  Education,
  EducationalInformation,
  IEmployeeInfo,
} from "../../types/IStaff";
import { useUpdateStaffMutation } from "../../hooks/mutations/useUpdateStaffMutaion";

interface IProps {
  employeeData?: IEmployeeInfo;
}

const EducationNotification: React.FC<IProps> = ({ employeeData }) => {
  const isEditMode = !!employeeData;
  const { mutate } = useUpdateStaffMutation();
  const [educationList, setEducationList] = useState<Education[]>([
    {
      educationLevel: "",
      university: "",
    },
  ]);

  const [certificateList, setCertificateList] = useState<Certificate[]>([
    {
      certificate: "",
      issuingOrganization: "",
    },
  ]);

  const [ceuUnit, setCeuUnit] = useState("");

  // Initialize data if in edit mode
  useEffect(() => {
    if (isEditMode && employeeData?.educationalInformation) {
      // Initialize education list
      if (employeeData.educationalInformation.education?.length > 0) {
        const educationData = employeeData.educationalInformation.education.map(
          (edu) => ({
            educationLevel: edu.educationLevel || "",
            university: edu.university || "",
          })
        );
        setEducationList(educationData);
      }

      // Initialize certificate list
      if (employeeData.educationalInformation.certificates?.length > 0) {
        const certificateData =
          employeeData.educationalInformation.certificates.map((cert) => ({
            certificate: cert.certificate || "",
            issuingOrganization: cert.issuingOrganization || "",
          }));
        setCertificateList(certificateData);
      }

      // Initialize CEU units if available
      if (employeeData.educationalInformation.requiredCEUPointsPerYear) {
        setCeuUnit(
          employeeData.educationalInformation.requiredCEUPointsPerYear
        );
      }
    }
  }, [isEditMode, employeeData]);

  const handleAddEducation = () => {
    setEducationList([
      ...educationList,
      { educationLevel: "", university: "" },
    ]);
  };

  const handleAddCertificate = () => {
    setCertificateList([
      ...certificateList,
      { certificate: "", issuingOrganization: "" },
    ]);
  };

  const handleRemoveEducation = (index: number) => {
    const newList = educationList.filter((_, i) => i !== index);
    setEducationList(newList);
  };

  const handleRemoveCertificate = (index: number) => {
    const newList = certificateList.filter((_, i) => i !== index);
    setCertificateList(newList);
  };

  const handleEducationChange = (
    index: number,
    field: keyof Education,
    value: string
  ) => {
    const newList = [...educationList];
    newList[index][field] = value;
    setEducationList(newList);
  };

  const handleCertificateChange = (
    index: number,
    field: keyof Certificate,
    value: string
  ) => {
    const newList = [...certificateList];
    newList[index][field] = value;
    setCertificateList(newList);
  };

  const handleSave = () => {
    // Implement your save logic here
    const updatedData: any = {
      educationalInformation: {
        education: educationList,
        certificates: certificateList,
        requiredCEUPointsPerYear: ceuUnit,
      },
    };

    console.log("updatedData", updatedData);
    if (employeeData?._id) {
      mutate({ staffId: employeeData._id, updatedData: updatedData });
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">Education</h2>

        {educationList.map((education, index) => (
          <div key={index} className="mb-6">
            <div className="grid grid-cols-2 gap-x-7 gap-y-9">
              <div>
                <ReactSelect
                  options={educationLevels}
                  label="Education Level"
                  placeholder="Select Education"
                  required
                  value={educationLevels?.find(
                    (option) => option?.value === education.educationLevel
                  )}
                  onChange={(option) =>
                    handleEducationChange(
                      index,
                      "educationLevel",
                      option?.value || ""
                    )
                  }
                />
              </div>
              <div className="relative">
                <label className="text-secondaryVariant text-base mb-1 block">
                  University
                </label>
                <input
                  value={education.university}
                  onChange={(e) =>
                    handleEducationChange(index, "university", e.target.value)
                  }
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
                {index > 0 && (
                  <button
                    onClick={() => handleRemoveEducation(index)}
                    className="absolute right-[-30px] top-[40px]"
                    type="button"
                  >
                    <CiCircleMinus className="w-6 h-6 text-red-500" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}

        <button
          className="text-base text-statusColor flex items-center gap-2 mb-9"
          type="button"
          onClick={handleAddEducation}
        >
          <IoIosAddCircleOutline className="w-6 h-6" />
          Add Education
        </button>

        <h2 className="text-xl text-secondary font-semibold mb-9">
          Certification
        </h2>

        {certificateList.map((cert, index) => (
          <div key={index} className="grid grid-cols-2 gap-x-7 gap-y-9 mb-6">
            <div>
              <ReactSelect
                options={certifications}
                label="Certificate"
                placeholder="Select Certification"
                required
                value={certifications?.find(
                  (option) => option?.value === cert.certificate
                )}
                onChange={(option) =>
                  handleCertificateChange(
                    index,
                    "certificate",
                    option?.value || ""
                  )
                }
              />
            </div>
            <div className="relative">
              <label className="text-secondaryVariant text-base mb-1 block">
                Issuing organization
              </label>
              <input
                type="text"
                value={cert.issuingOrganization}
                onChange={(e) =>
                  handleCertificateChange(
                    index,
                    "issuingOrganization",
                    e.target.value
                  )
                }
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              />
              {index > 0 && (
                <button
                  onClick={() => handleRemoveCertificate(index)}
                  className="absolute right-[-30px] top-[40px]"
                  type="button"
                >
                  <CiCircleMinus className="w-6 h-6 text-red-500" />
                </button>
              )}
            </div>
          </div>
        ))}

        <button
          className="text-base text-statusColor flex items-center gap-2 mb-9"
          type="button"
          onClick={handleAddCertificate}
        >
          <IoIosAddCircleOutline className="w-6 h-6" />
          Add Certificate
        </button>

        <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
          <div>
            <ReactSelect
              label="Required CEU Units (Per Year)"
              placeholder="Select Options"
              required
              options={ceuUnits}
              value={ceuUnits?.find((option) => option?.value === ceuUnit)}
              onChange={(option) => setCeuUnit(option?.value || "")}
            />
          </div>
        </div>

        <div className="flex items-center gap-3">
          <button className="btnPrimary max-w-[181px]" onClick={handleSave}>
            {isEditMode ? "Update" : "Save"}
          </button>
          <button className="btnSimple max-w-[181px]">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default EducationNotification;
