import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  calculateAgeYM,
  formatDateSlashFullYear,
} from "../../../../shared/utils/formatDate";
import { forayRoutes } from "../../../routes/routes";
import { useStudentPreviews } from "../../hooks/useStudentPreviews";
import { IStudentInfo } from "../../types/IStudentInfo";
import { useQuery } from "@tanstack/react-query";
import { IClassroomSetup } from "../../../types/IClassroomSetup.interface";
import { classroomSvc } from "../../../services/classroom.service";
import { Slot } from "../../../enums/Foray.enums";
import { Transition } from "../../../types/IStudentSetup.interface";

const columnHelper = createColumnHelper<IStudentInfo>();

interface IProps {
  students: string[];
  previewId: string;
}

const StudentEnrollmentTable: React.FC<IProps> = ({ students, previewId }) => {
  const { data: fullTimeStudents } = useStudentPreviews(
    students,
    previewId
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<IStudentInfo>();
  const { data: classroomSetups } = useQuery<IClassroomSetup[]>({
    queryKey: ["get-classroom-setups", students],
    queryFn: async () => {
      if (!students?.length) return;
      const res = await classroomSvc.fetchClassrooms();

      if (res?.ok) {
        return res?.data?.existingClassroomSetups;
      }
      return [];
    },
  });

  const handleGetClassroomName = (classroomId: string) => {
    if (!classroomId) return "";
    return (
      classroomSetups?.find((classroom) => classroom._id === classroomId)
        ?.classroomName || classroomId
    );
  };

  const handleGetTransitionSlotName = (transition: Transition) => {
    if (transition?.slot === Slot.FULL_TIME) {
      return Slot.FULL_TIME;
    }
    return (
      classroomSetups
        ?.find((classroom) => classroom._id === transition?.classroomId)
        ?.partTimeSlots?.find((slot) => slot?.uuid === transition?.slot)
        ?.name || transition?.slot
    );
  };

  const columns = [
    columnHelper.accessor("firstName", {
      id: "studentName",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Student Name</span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("dateOfBirth", {
      id: "age",

      header: () => (
        <div className="flex items-center gap-3">
          <span>Age</span>
        </div>
      ),
      cell: (info) => {
        const dob = info.getValue() as string;
        const { years, months } = calculateAgeYM(dob);
        return (
          <div className="flex items-center gap-3">
            <span className="w-full">
              {years}Y {months}M
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor("Dates.startAtCenter", {
      id: "startDate",
      header: () => (
        <div className="text-center">
          <span>Start Date</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue() as string)}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.firstTransition.date", {
      id: "t1",
      header: () => (
        <div className="text-center">
          <span>T1</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue()) || "--"}
        </div>
      ),
    }),

    columnHelper.accessor("transitions.firstTransition.classroomId", {
      id: "t1Class",
      header: () => (
        <div className="text-center">
          <span>T1 Class</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {handleGetClassroomName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.firstTransition", {
      id: "session",
      header: () => (
        <div className="text-center">
          <span>Session</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center rounded py-1 px-1.5 bg-successAlert text-white">
          {handleGetTransitionSlotName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.secondTransition.date", {
      id: "t2",
      header: () => (
        <div className="text-center">
          <span>T2</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.secondTransition.classroomId", {
      id: "t2Class",
      header: () => (
        <div className="text-center">
          <span>T2 Class</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {handleGetClassroomName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.secondTransition", {
      id: "session2",
      header: () => (
        <div className="text-center">
          <span>session</span>
        </div>
      ),
      cell: (info) => (
        <div className={`text-center rounded py-1 px-1.5 ${info.getValue() ? "bg-successAlert text-white" : ""} `}>
          {handleGetTransitionSlotName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.display({
      id: "action",
      header: () => (
        <div className="text-end">
          <span>Action</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-end">
          <Link
            to={forayRoutes.student_detail_edit}
            state={{ studentId: info.row.original._id, previewId }}
          >
            <BiEdit className="w-6 h-6 text-statusColor" />
          </Link>
        </div>
      ),
    }),
  ];

  const onClose = () => {
    setIsEditOpen(false);
  };

  const table = useReactTable({
    data: fullTimeStudents || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="scrollHide max-h-[300px] relative z-[10px]  pb-2">
      <table className="w-full bg-white rounded-t-lg">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-2.5 text-left text-base font-medium text-secondary capitalize whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows?.map((row) => (
            <tr key={row.id} className="hover:bg-secondaryNeutral">
              {row?.getVisibleCells()?.map((cell) => (
                <td
                  key={cell.id}
                  className="px-5 py-1.5 text-base text-secondaryVariant border-b border-secondaryNeutral whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  {flexRender(cell.column?.columnDef?.cell, cell?.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentEnrollmentTable;
