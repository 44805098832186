import React from "react";

const CcfHomeBanner = () => {
  return (
    <>
      <div className="ccfHomeBanner relative">
        <div className="flex items-center flex-row container">
          <div className="lg:basis-3/4 py-6 sm:py-12 md:py-8 lg:py-[80px] ">
            <div className="max-w-[552px] lg:bg-transparent bg-white/70 lg:p-0 p-6 lg:rounded-none rounded-lg">
              <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-bold mb-2 xl:mb-6">
                Taking the Child Care <br /> Industry{" "}
                <span className="text-primary italic"> Forward!</span>
              </h2>
              <p className="text-lg text-secondary">
                Transforming childcare operations through innovative services
                and technology​.
              </p>
            </div>
          </div>
          <div className="ccfBannerImg absolute right-0 top-0">
            <img
              className=" lg:h-[292px] xl:h-[320px] hidden lg:block"
              src="https://a360csastorage.blob.core.windows.net/childcare/f708ef15-aec9-4d94-b651-e128a08f87d0-fileName-ccf-hom-banner-bg.png"
              alt="banner"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CcfHomeBanner;
