import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { ICategory } from "../../types/ICategories.interface";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import TimeDropdown from "../dropdowns/TimeDropdown";
import { timeOptions } from "../../constants/constantValues";
import { useAddCategory } from "../../hooks/useAddCategory";
import moment from "moment";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: string
  selectedCard: ICategory
}

const AddCategoryModal: React.FC<ModalProps> = ({ isOpen, onClose, modalType, selectedCard }) => {
  const [categoryDetail, setCategoryDetail] = useState({ title: "", scheduledTime: "" })
  const categoryModalRef = useRef<HTMLDivElement>(null);
  const { isSuccess: addCategorySuccess, mutate: addCategory } = useAddCategory()

  const handleInputFieldChange = (fieldName: string, value: string) => {
    setCategoryDetail((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  const handleAddClick = () => {
    if (categoryDetail.title && categoryDetail.scheduledTime) {
      addCategory({
        title: categoryDetail.title,
        scheduledTime: categoryDetail.scheduledTime,
      });
    } else {
      console.error("Title or Scheduled Time is missing.");
    }
  }

  useEffect(() => {
    if (!addCategorySuccess) return;
    onClose()
    setCategoryDetail({
      title: "",
      scheduledTime: ""
    })
  }, [addCategorySuccess])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        categoryModalRef.current &&
        !categoryModalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!selectedCard?._id) {
      setCategoryDetail({
        title: "",
        scheduledTime: ""
      })
      return
    };
    setCategoryDetail({
      title: selectedCard?.title,
      scheduledTime: selectedCard?.scheduledTime
    })
  }, [selectedCard])

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[99999]">
      <div ref={categoryModalRef} className="bg-white px-6 py-4 rounded-lg shadow-lg w-[464px]">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-secondary">
            {modalType === "add" ? "Add" : "Edit"} Category
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div>

        <div className="py-4 space-y-[18px]">
          <div>
            <label
              htmlFor=""
              className="text-[10px] text-secondaryVariant mb-0.5"
            >
              Category Name<RequiredAsterisk />
            </label>
            <input
              type="text"
              placeholder="Name"
              name="title"
              className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
              value={categoryDetail?.title}
              onChange={(e) => { handleInputFieldChange(e.target.name, e.target.value) }}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="text-[10px] text-secondaryVariant mb-0.5"
            >
              Time (Optimal time to execute tasks)<RequiredAsterisk />{" "}
            </label>
            <input
              type="time"
              placeholder="13:00"
              className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
              value={
                categoryDetail?.scheduledTime
                  ? moment(categoryDetail?.scheduledTime, "hh:mm A").format("HH:mm")
                  : ""
              }
              onChange={(e) => {
                const formattedTime = moment(e.target.value, "HH:mm").format("hh:mm A");
                handleInputFieldChange("scheduledTime", formattedTime);
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          <button className="text-sm font-medium text-secondaryVariant2" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btnPrimary max-w-[81px] flex justify-center"
            onClick={handleAddClick}
          >
            {modalType === "add" ? "Add" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryModal;
