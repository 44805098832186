import { useQuery } from "@tanstack/react-query";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";
import { WeekDay } from "../enums/Foray.enums";

export const useDailyChecklist = (weekDay: WeekDay) => {
  return useQuery({
    queryKey: [ChecklistQueryKeyString.DailyChecklist],
    queryFn: () => checklistSvc.fetchDailyCheckLists(weekDay),
  });
};
