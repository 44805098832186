import * as React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { RiDeleteBin3Line } from "react-icons/ri";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Classroom, IClassroomSetup } from "../../types/IClassroomSetup.interface";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { SessionType } from "../../enums/Foray.enums";
interface ActionDropdownProps {
  classroomId: string;
  isActive: boolean;
  isOpen: boolean;
  toggleDropdown: (id: string) => void;
  fetchAgain: boolean;
  setFetchAgain?: (fetch: boolean) => void;
}
const ActionDropdown: React.FC<ActionDropdownProps> = ({
  classroomId,
  isActive,
  isOpen,
  toggleDropdown,
  fetchAgain,
  setFetchAgain,
}) => {
  const actionModalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(actionModalRef, () => isOpen && toggleDropdown(classroomId));
  const navigate = useNavigate();

  const handleView = () => {
    navigate(forayRoutes.view_classroom, { state: { classroomId } });
  };

  const handleModify = () => {
    navigate(forayRoutes.detailed_classroom_setup, { state: { classroomId } });
  };

  const handleArchive = async () => {
    const res = await classroomSvc.archiveClassroom(classroomId, !isActive);
    if (res?.ok) {
      toggleDropdown(classroomId);
      setFetchAgain && setFetchAgain(!fetchAgain);
    }
  };

  return (
    <div className="relative text-end">
      <button
        onClick={() => toggleDropdown(classroomId)}
        className="focus:outline-none"
      >
        <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
      </button>
      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-10 p-4"
          ref={actionModalRef}
        >
          <ul>
            <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleModify}
            >
              <button className="text-base text-secondary flex items-center gap-4">
                <BiEdit className="w-6 h-6 text-secondaryVariant2" />
                Modify
              </button>
            </li>
            <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleArchive}
            >
              <button className="text-base text-dangerAlert flex items-center gap-4">
                <RiDeleteBin3Line className="w-6 h-6" />
                Deactivate
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};



const columnHelper = createColumnHelper<IClassroomSetup>();
interface ClassroomListTableProps {
  classrooms: IClassroomSetup[];
  handleAddClassroomDetail: () => void;
  fetchAgain?: boolean;
  setFetchAgain?: (fetch: boolean) => void;
}
const ClassroomDetailedListTable: React.FC<ClassroomListTableProps> = ({
  classrooms,
  handleAddClassroomDetail,
  setFetchAgain,
}) => {
  const [data, _setData] = React.useState(classrooms);
  const [openDropdownId, setOpenDropdownId] = React.useState<string | null>(
    null
  );
  let hasRenderedEmptyColumn = false;

  const navigate = useNavigate()
  console.log("check data: ", classrooms)
  React.useEffect(() => {
    if (!classrooms?.length) return;
    _setData(classrooms)

  }, [classrooms])

  const handleGetNameOfTransitionClassroom = (classroomId: string) => {
    const transitionClass = classrooms?.find((item) => item._id === classroomId)
    return transitionClass?.classroomName
  }



  const columns = [
    columnHelper.accessor("classroomName", {
      id: "classroomName",
      header: () => <div className="break-words">Classroom Name</div>,
      cell: (info) => (
        <div className="break-words">
          <span className="w-full">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("nickName", {
      id: "nickName",
      header: () => <div className="break-words">Nick Name</div>,
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">{info.getValue() || "--"}</span>
        </div>
      ),
    }),

    columnHelper.accessor("capacity.licenseCapacity", {
      id: "licenseCapacity",
      header: () => (
        <div className="text-start">
          <span>License Capacity</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("capacity.physicalCapacity", {
      id: "physicalCapacity",
      header: () => (
        <div className="text-center">
          <span>Physical Capacity</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("selfBreaking", {
      id: "selfBreak",
      header: () => (
        <div className="text-start">
          <span>Self Break</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          <span className={`${info.getValue() ? "bg-[#E6F2FE] text-statusColor" : "bg-[#FCEBE9] text-dangerAlert"} py-1 px-1.5 rounded text-xs font-medium`}>
            {" "}
            {info.getValue() ? "Yes" : "No"}
          </span>{" "}
          {/* <span className=" py-1 px-1.5 rounded text-xs font-medium">
          {" "}
          {info.getValue()}
        </span>{" "} */}
        </div>
      ),
    }),

    columnHelper.accessor("capacity.studentPerTeacher", {
      id: "stuTeachRatio",
      header: () => (
        <div className="text-start">
          <span>StuTeach Ratio</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("transition.TransitionClassroom", {
      id: "nextClassroom",
      header: () => (
        <div className="text-start">
          <span>Next Classroom</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{handleGetNameOfTransitionClassroom(info.getValue() as string) || ""}</div>,
    }),
    columnHelper.accessor("BAProgram", {
      id: "bAProgram",
      header: () => (
        <div className="text-start">
          <span>BA Program</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          {" "}
          <span className={`${info.getValue() ? "bg-[#E6F2FE] text-statusColor" : "bg-[#FCEBE9] text-dangerAlert"} py-1 px-1.5 rounded text-xs font-medium`}>
            {" "}
            {info.getValue() ? "Yes" : "No"}
          </span>{" "}
          {/* <span className="bg-[#FCEBE9] text-dangerAlert py-1 px-1.5 rounded text-xs font-medium">
          {" "}
          {info.getValue()}
        </span>{" "} */}
        </div>
      ),
    }),
    columnHelper.accessor("settingType", {
      id: "classroomSession",
      header: () => (
        <div className="text-start min-w-[130px]">
          <span>
            Classroom <br /> Session
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start min-w-[130px]">
          {" "}
          <span className={`${info.getValue() === SessionType.FullTimeOnly ? "bg-[#00C14F]" : "bg-[#FFAE00]"} text-white py-1 px-1.5 rounded text-xs font-medium`}>
            {" "}
            {info.getValue() || "None"}
          </span>{" "}
          {
            info.row.original.partTimeSlots.map((slot) => (
              <div className="text-xs text-secondaryVariant mt-1">
                {slot.startTime} to {slot.endTime}
              </div>
            ))
          }
        </div >
      ),
    }),
    columnHelper.accessor("_id", {
      id: "action",
      header: () => (
        <div className="text-end">
          <span>Action</span>
        </div>
      ),
      cell: (row) => (
        <ActionDropdown
          classroomId={row.row.original?._id as string}
          isActive={row.row.original.isActive as boolean}
          isOpen={openDropdownId === row.row.original?._id}
          toggleDropdown={toggleDropdown}
          // fetchAgain={fetchAgain}
          setFetchAgain={setFetchAgain && setFetchAgain}
          fetchAgain={false}
        />
      ),
    }),
    columnHelper.accessor("_id", {
      id: "empty",
      header: () => (<></>),
      cell: (row) => (
        <div className="max-w-[711px] flex items-center gap-3 flex-col justify-center mx-auto py-3">
          <p className="text-base text-secondaryVariant">
            As you finished your Essential classroom, now you need to add
            classroom details.
          </p>
          <button className="btnPrimary max-w-[220px]" onClick={handleAddClassroomDetail}>
            Add Classroom Details
          </button>
        </div>
      ),
    }),
  ];

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="scrollHide   overflow-y-auto h-[calc(100vh-477px)] border-secondaryVariant2 border rounded-xl mb-6 bg-white">
      <table className="w-full bg-white rounded-t-lg ">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="p-4 text-left text-base font-medium text-secondary capitalize"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows.map((row) => {
            const { licenseCapacity, physicalCapacity, studentPerTeacher, } = row.original.capacity || {};
            const showFullRow = licenseCapacity && physicalCapacity && studentPerTeacher && row.original.settingType;
            return (
              <tr key={row.id} className="odd:bg-secondaryNeutral">
                {row.getVisibleCells().map((cell) => {

                  if (!showFullRow && cell.column.id === "empty" && !hasRenderedEmptyColumn) {
                    hasRenderedEmptyColumn = true;
                    return (
                      <td
                        key={cell.id}
                        colSpan={row.getVisibleCells().length}
                        rowSpan={table.getRowModel().rows.length}
                        className="p-4 text-base text-secondaryVariant border-b border-secondaryNeutral capitalize text-center"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }

                  if (cell.column.id === "classroomName") {
                    return (
                      <td
                        key={cell.id}
                        className="p-4 text-base text-secondaryVariant border-b border-secondaryNeutral capitalize"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }

                  // Show other columns only if showFullRow is true
                  if (showFullRow && cell.column.id !== "empty") {
                    return (
                      <td
                        key={cell.id}
                        className="p-4 text-base text-secondaryVariant border-b border-secondaryNeutral capitalize"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }



                  return null
                })}

              </tr>
            )
          })}
        </tbody>
      </table>
      {/* <div className="max-w-[711px] flex items-center gap-3 flex-col justify-center mx-auto py-10">
        <p className="text-base text-secondaryVariant">
          As you finished your Essential classroom, now you need to add
          classroom details.
        </p>
        <button className="btnPrimary max-w-[220px]" onClick={handleAddClassroomDetail}>
          Add Classroom Details
        </button>
      </div> */}
    </div>
  );
};

export default ClassroomDetailedListTable;
function setFetchAgain(fetch: boolean): void {
  throw new Error("Function not implemented.");
}

function toggleDropdown(id: string): void {
  throw new Error("Function not implemented.");
}
