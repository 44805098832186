import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { IOption } from "../../types/ICenterSetup.interface";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";

const options = [
  // { value: "Select Country", label: "Select Country" },
  { value: "USA", label: "USA" },
];

interface Props {
  label: string;
  placeholder: string;
  value?: IOption | null; // Controlled value passed from parent
  onChange?: (selectedOption: SingleValue<IOption>) => void; // onChange handler passed from parent
  options?: IOption[]; // Options passed from parent
  required?: boolean;
  isDisabled?: boolean; // Optional prop to disable the select
}

const ReactSelect: React.FC<Props> = ({
  label,
  placeholder,
  value,
  onChange,
  options,
  required = false,
  isDisabled = false, // Default value for isDisabled is false
}) => {
  return (
    <div className="w-full">
      <label htmlFor="select" className="text-base text-secondaryVariant block">
        {label}
        {required && <RequiredAsterisk />}
      </label>
      <Select
        value={value}
        onChange={onChange} // Controlled value and change handler
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled} // Disable the select if required
        className="w-full mt-1"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            borderColor: state.isFocused ? "grey" : "#CED2DA",
            padding: "8px 8px 8px 4px",
            borderRadius: "8px",
          }),
        }}
      />
    </div>
  );
};

export default ReactSelect;
