import { useQuery } from "@tanstack/react-query";
import { Months } from "../enums/Foray.enums";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";

export const useMonthlyChecklist = (month: Months) => {
  return useQuery({
    queryKey: [ChecklistQueryKeyString.MonthlyChecklist],
    queryFn: () => checklistSvc.fetchMonthlyCheckLists(month),
  });
};
