import {
    ICategory,
    ISectionUpdateFiltersResponse,
} from "../types/ICategories.interface";
import {
    ISectionUpdate,
    ITaskGenerationFilters,
} from "../types/ITaskGeneration.interface";
import { apiForay } from "../utils/axios";

const fetchCategories = async () => {
  try {
    const res = await apiForay.get<ICategory[]>(`task-generation/categories`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

//scheduledTime must be in  HH:mm format (24-hour)
const createCategory = async (title: string, scheduledTime: string) => {
  try {
    const res = await apiForay.post<ICategory>(
      `task-generation/create/category`,
      { title, scheduledTime }
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateSectionFilters = async (
  categoryId: string,
  sectionId: string,
  filters: ITaskGenerationFilters
) => {
  try {
    const res = await apiForay.post<ISectionUpdateFiltersResponse>(
      `task-generation/update-filters/${categoryId}/${sectionId}`,
      filters
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const addSectionToCategory = async (
  categoryId: string,
  body: ISectionUpdate
) => {
  try {
    const res = await apiForay.put<ICategory>(
      `task-generation/${categoryId}/add-section`,
      body
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateCategory = async (categoryId: string, body: any) => {
  try {
    const res = await apiForay.put<ICategory>(
      `task-generation/category/${categoryId}/update`,
      body
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateSection = async (
  categoryId: string,
  sectionId: string,
  body: ISectionUpdate
) => {
  try {
    const res = await apiForay.put<ICategory>(
      `task-generation/category/${categoryId}/section/${sectionId}/rename`,
      body
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const deleteCategory = async (categoryId: string) => {
  try {
    const res = await apiForay.delete(`task-generation/category/${categoryId}`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const deleteSection = async (categoryId: string, sectionId: string) => {
  try {
    const res = await apiForay.delete(
      `task-generation/category/${categoryId}/section/${sectionId}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const taskGenerationSvc = {
  fetchCategories,
  createCategory,
  updateSectionFilters,
  addSectionToCategory,
  updateCategory,
  updateSection,
  deleteCategory,
  deleteSection,
};
