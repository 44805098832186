import React from "react";
import { Link } from "react-router-dom";
const newsData = [
  {
    id: 1,
    description: "Updated information on financial assistance for Child Care. ",
    linkText: "https://childcare.gov/",
    link: "https://childcare.gov/",
  },
  {
    id: 2,
    description: "Early language development is critical for future success.",
    linkText:
      "Presidential-roundtable-working-paper_Siraj-et-al_final (2).pdf | Powered by Box",
    link: "https://rutgers.app.box.com/s/a5bjl80hes19qtw4yilhz0bpkkrm42fo",
  },
  {
    id: 3,
    description:
      "Optimal Early Education in autistic children improves working memory development.",
    linkText:
      "The long-lasting benefits of pre-kindergarten education on autistic children’s working memory development (sagepub.com)",
    link: "https://journals.sagepub.com/doi/pdf/10.1177/13623613241265996",
  },
  {
    id: 4,
    description:
      "New research highlights the importance of play in child development.",
    linkText: "Importance of Play (example.com)",
    link: "/",
  },
  {
    id: 5,
    description: "Strategies for improving early literacy skills in children.",
    linkText: "Literacy Strategies (example.com)",
    link: "/",
  },
];

const NewsAndResearch = () => {
  return (
    <div className="h-full rounded-b-lg bg-[#f5f5f5]">
      <h2 className="xl:text-2xl lg:text-xl font-semibold text-white py-2.5 px-4 rounded-t-lg bg-secondary">
        News and Research
      </h2>
      <div className="pt-4 xl:px-4 px-3 space-y-3">
        {newsData.slice(0, 3).map((item) => (
          <div
            key={item.id}
            className="rounded bg-white xl:px-3 px-2 pt-3 pb-4"
          >
            <p className="text-sm text-secondaryVariant font-medium line-clamp-2">
              {item.description}
            </p>
            <Link
              to={item.link}
              target="_blank"
              className="text-sm text-statusColor font-medium underline"
            >
              {item.linkText}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsAndResearch;
