interface IProps {
  profileUrl: string;
}

const ProfileCard: React.FC<IProps> = ({ profileUrl }) => {
  return (
    <div className="bg-white p-6 rounded-xl flex flex-wrap items-center gap-6">
      <div className="rounded-full w-[173px] h-[173px]">
        <img src={profileUrl} alt="" className="rounded-full w-full h-full" />
      </div>
      <div className="flex-1 space-y-6">
        <p>
          Personalize your account with a photo. Your profile photo will appear
          on your account.
        </p>
        <button className="text-primary border border-primary py-2 px-3 rounded-lg hover:bg-primary hover:text-white">
          Change photo
        </button>
      </div>
    </div>
  );
};

export default ProfileCard;
