import React from "react";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";
import { GoArrowRight } from "react-icons/go";

const FrLandingScreen = () => {
  return (
    <>
      <MainWrapper>
        <div className="mb-4 md:mb-10 xl:mb-16">
          <HeroBanner
            forayBanner={true}
            title="oray"
            highlightedText="F"
            paragraphText={""}
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/a0317cc0-5be3-48ad-8d73-599ac16fc1e5-fileName-blogs-banner.png"
          />{" "}
        </div>
        <div className="container mb-4 md:mb-10 xl:mb-16">
          <div className="grid grid-cols-2 gap-2">
            <div className="space-y-6">
              <h2 className="text-[28px] text-secondary font-bold max-w-[503px]">
                Most <span className="text-primary italic">Forward!</span>{" "}
                Looking Software… It’s so Advanced - it’s simple 
              </h2>
              <p className="text-lg text-secondaryVariant">
                This is a quantum leap in helping Centers organize their
                enrollment.  Once you set up the relevant parameters, all
                student movements are automated - and for 365 days in advance! 
                The read out is a simple dashboard.  You now have control of
                your center's enrollment - looking forward one whole year. 
                What's more, you can OPTIMIZE enrollments and transitions and
                provide parents with precise dates of enrollment - always for
                365 days in advance.   This is just the beginning...there's lots
                more!
              </p>
              <button className="btnPrimary max-w-[160px] gap-3">
                Learn more <GoArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div>
              <img
                className="w-full"
                src="https://a360csastorage.blob.core.windows.net/childcare/518662de-8b71-4c1e-a4fb-9e2b6f924e81-fileName-season-title-img.png"
                alt="Enrollment Tracking"
              />
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default FrLandingScreen;
