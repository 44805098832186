import { NewClassroom } from "../../types/IClassroomSetup.interface";

export const convertToDays = (
  days: number = 0,
  months: number = 0,
  years: number = 0
): number => {
  return days + months * 31 + years * 365;
};

export const validateClassrooms = (
  classrooms: NewClassroom[],
  checkOverlapOnly: boolean = false // New conditional prop
): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];
  if (classrooms) {
    // Check if classrooms are empty
    if (classrooms?.length === 0) {
      errors.push("At least one classroom must be added.");
      return { isValid: false, errors };
    }

    // Sort classrooms by minimum age
    const sortedClassrooms = [...classrooms]?.sort(
      (a, b) =>
        convertToDays(
          a.ageRange.minimumAge.days,
          a.ageRange.minimumAge.months,
          a.ageRange.minimumAge.years
        ) -
        convertToDays(
          b.ageRange.minimumAge.days,
          b.ageRange.minimumAge.months,
          b.ageRange.minimumAge.years
        )
    );

    // Validate classroom names are unique and not empty
    const classroomNames = new Set();
    sortedClassrooms?.forEach((classroom, index) => {
      const trimmedName = classroom.classroomName.trim();

      // Check for empty name
      if (!trimmedName) {
        errors.push(`Classroom ${index + 1} must have a name.`);
      }

      // Check for duplicate names
      if (classroomNames.has(trimmedName)) {
        errors.push(`Duplicate classroom name: ${trimmedName}`);
      }
      classroomNames.add(trimmedName);
    });

    // Check for sequential or overlapping ages
    for (let i = 0; i < sortedClassrooms?.length; i++) {
      const currentClassroom = sortedClassrooms[i];
      const currentMinDays = convertToDays(
        currentClassroom.ageRange.minimumAge.days,
        currentClassroom.ageRange.minimumAge.months,
        currentClassroom.ageRange.minimumAge.years
      );
      const currentMaxDays = convertToDays(
        currentClassroom.ageRange.maximumAge.days,
        currentClassroom.ageRange.maximumAge.months,
        currentClassroom.ageRange.maximumAge.years
      );

      // Validate min age is less than max age
      if (currentMinDays >= currentMaxDays) {
        errors.push(
          `${currentClassroom.classroomName}: Minimum age must be less than maximum age.`
        );
      }

      if (i > 0) {
        const prevClassroom = sortedClassrooms[i - 1];
        const prevMaxDays = convertToDays(
          prevClassroom.ageRange.maximumAge.days,
          prevClassroom.ageRange.maximumAge.months,
          prevClassroom.ageRange.maximumAge.years
        );

        // Check for gaps
        if ((currentMinDays > prevMaxDays + 1) && !checkOverlapOnly) {
          errors.push(
            `Gap detected between ${prevClassroom.classroomName} and ${currentClassroom.classroomName}`
          );
        }

        // Check for overlaps
        if (currentMinDays <= prevMaxDays) {
          errors.push(
            `Overlap detected between ${prevClassroom.classroomName} and ${currentClassroom.classroomName}`
          );
        }
      }
    }
  }
  return {
    isValid: errors.length === 0,
    errors,
  };
};
