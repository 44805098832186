import React from "react";
import { HiOutlineUser } from "react-icons/hi2";

const ForayDashboardCard = () => {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="bg-white border-secondaryNeutral border rounded-xl p-5 space-y-4">
          <div className="rounded-md p-2 bg-secondaryNeutral max-w-max">
            <HiOutlineUser className="text-[#6E8FA5] w-6 h-6" />
          </div>
          <h3 className="text-secondary font-medium text-4xl flex items-start flex-col gap-2">
            28{" "}
            <span className="text-base font-normal text-secondaryVariant">
              Children Enrolled
            </span>
          </h3>
        </div>
        <div className="bg-white border-secondaryNeutral border rounded-xl p-5 space-y-4">
          <div className="rounded-md p-2 bg-secondaryNeutral max-w-max">
            <HiOutlineUser className="text-[#6E8FA5] w-6 h-6" />
          </div>
          <h3 className="text-secondary font-medium text-4xl flex items-start flex-col gap-2">
            28{" "}
            <span className="text-base font-normal text-secondaryVariant">
              Title Here
            </span>
          </h3>
        </div>
        <div className="bg-white border-secondaryNeutral border rounded-xl p-5 space-y-4">
          <div className="rounded-md p-2 bg-secondaryNeutral max-w-max">
            <HiOutlineUser className="text-[#6E8FA5] w-6 h-6" />
          </div>
          <h3 className="text-secondary font-medium text-4xl flex items-start flex-col gap-2">
            28{" "}
            <span className="text-base font-normal text-secondaryVariant">
              Title Here
            </span>
          </h3>
        </div>
      </div>
    </>
  );
};

export default ForayDashboardCard;
