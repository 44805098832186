
import { TiStar } from "react-icons/ti";

const TrustedCustomerCard = ({ testimonial }:any) => {
  return (
    <>
      <p className="xl:text-lg text-base text-secondary xl:mb-9 mb-3">
        “{testimonial.quote}”
      </p>
      <strong className="text-lg text-secondary font-semibold mb-3">
        {testimonial.name}
      </strong>

      <div className="flex items-center">
        {[...Array(5)].map((_, index) => (
          <TiStar key={index} className="w-[18px] h-[18px] text-[#FFB21A]" />
        ))}
        <span className="ms-1 text-sm text-secondaryVariant">{testimonial.rating.toFixed(1)}</span>
      </div>
    </> 
  );
};

export default TrustedCustomerCard;
